import { gql, useMutation } from '@creditas/apollo'

export interface Termination {
  id: string
  conciliatorName: string
  conciliatorEmail: string
  clientName: string
  clientDocumentValue: string
  companyDocumentValue: string
  loanType: string
  shouldPayrollDiscountFlag: boolean
  submitted?: boolean
  terminationAt: string
  conciliationDate: string
  terminationAmount: number | null | string
}

export const TERMINATION = gql`
  mutation Termination($termination: Termination!) {
    termination(termination: $termination)
  }
`

const useTermination = () => {
  return useMutation(TERMINATION)
}

export { useTermination }
