import { useTranslation } from '@creditas/i18n'
import React, {
  useState,
  useEffect,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from 'react'

import { TextField } from '@creditas/input'

import {
  getNextMonthYear,
  getNextMonth,
  DateSelectProps,
  generateMonthOptions,
  generateYearOptions,
} from '.'

import { DateSelectContainer } from './DateSelect.style'
import moment from 'moment'

export const DateSelect = ({
  referenceDate,
  yearLabel = 'Year',
  monthLabel = 'Month',
  fromYear = '2017',
  onChange = () => {},
  toYear = getNextMonthYear(),
}: Partial<DateSelectProps>) => {
  const { t } = useTranslation()
  const locale = t('locale')
  const monthOptions = generateMonthOptions(locale)
  const yearOptions = generateYearOptions(fromYear, toYear)
  const [year, setYear] = useState(
    moment(referenceDate).format('YYYY') || getNextMonthYear(),
  )
  const [month, setMonth] = useState(
    moment(referenceDate).format('MM') || getNextMonth(),
  )

  useEffect(() => {
    onChange(moment(`${year}-${month}-01`, 'YYYY-MM-DD').toDate())
  }, [month, year])

  function handleChange(setStateAction: Dispatch<SetStateAction<string>>) {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      setStateAction(event.target.value)
    }
  }

  return (
    <DateSelectContainer data-testid="date-select-container">
      <TextField
        select
        value={month}
        label={monthLabel}
        onChange={handleChange(setMonth)}
        InputProps={{ 'data-testid': 'month-select' }}
      >
        {monthOptions.map(({ label, value }) => (
          <option key={value} label={label} value={value} />
        ))}
      </TextField>
      <TextField
        select
        value={year}
        label={yearLabel}
        onChange={handleChange(setYear)}
        InputProps={{ 'data-testid': 'year-select' }}
      >
        {yearOptions.map(({ value, label }) => (
          <option key={value} value={value} label={label} />
        ))}
      </TextField>
    </DateSelectContainer>
  )
}
