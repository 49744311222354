import {
  TimelineContent,
  TimelineLine,
  TimeLineText,
  TimeLineAction,
} from './Style'
import React, { useEffect, useState } from 'react'
import { Typography } from '@creditas/typography'
import { Navigation } from '@creditas/icons'

export const RightIcon = Navigation.ChevronRight
export const LeftIcon = Navigation.ChevronLeft

export interface TimelineProps {
  'data-testid': string
  steps: number
  current: number
  placeholder?: string
  disabled?: boolean
  onChange: (value: number) => void
}

const Timeline = ({
  'data-testid': testId,
  steps,
  current = 1,
  placeholder = 'of',
  onChange,
  disabled,
}: TimelineProps) => {
  const [step, setStep] = useState(current)

  function setBackActive(): string {
    return step > 1 ? 'active' : ''
  }

  function setNextActive(): string {
    return step < steps && !disabled ? 'active' : ''
  }

  function next() {
    if (!disabled && step < steps) {
      onChange(step + 1)
      setStep(step + 1)
    }
  }

  function back() {
    if (step > 1) {
      onChange(step - 1)
      setStep(step - 1)
    }
  }

  useEffect(() => {
    setStep(current)
  }, [current])

  return steps > 1 ? (
    <TimelineContent data-testid={testId}>
      <TimeLineText>
        <TimeLineAction
          className={setBackActive()}
          data-testid={'timeline-prev'}
          onClick={back}
        >
          <LeftIcon />
        </TimeLineAction>
        <Typography variant="paragraph">
          {step} {placeholder} {steps}
        </Typography>
        <TimeLineAction
          className={setNextActive()}
          data-testid={'timeline-next'}
          onClick={next}
        >
          <RightIcon />
        </TimeLineAction>
      </TimeLineText>
      <TimelineLine style={{ width: `${(step * 100) / steps}%` }} />
    </TimelineContent>
  ) : (
    <span />
  )
}

export default Timeline
