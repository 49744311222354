import React from 'react'
import { styled, utilities } from '@creditas/stylitas'
import { Tag } from '@creditas/tag'

const CustomTag = styled(Tag)`
  border: none;
  background-color: ${({ theme }) =>
    utilities.palette.color(theme, 'secondary01')};
  span {
    display: none;
  }
  p {
    font-weight: 700;
    color: ${({ theme }) => utilities.palette.color(theme, 'secondary05')};
  }
`

export { CustomTag }
