import { styled } from '@creditas/stylitas'
import tokens from '@creditas/tokens'
import { TableCell as T, TableRow as TR } from '@creditas/table'
import { Alert } from '@creditas/icons'

export const Info = styled(Alert.Info)`
  position: relative;
  top: 10px;
  margin-top: -10px;
`

export const TableCellStatus = styled(T)`
  min-width: 240px;
`

export const TableCell = styled(T)`
  vertical-align: middle;
`

export const TableCellCompact = styled(T)`
  max-width: 160px;
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const TableRow = styled(TR)`
  &.checked:nth-of-type(odd) {
    background-color: ${tokens.bColordangerlight};
  }
  &.checked:nth-of-type(even) {
    background-color: ${tokens.bColordangerlight}80;
  }
`
