import moment from 'moment'

function getCurrentMonth(currentDate: Date) {
  return moment(currentDate).toDate()
}

function getNextMonth(currentDate: Date) {
  return moment(currentDate)
    .add(1, 'M')
    .toDate()
}

function getInitialDate(): Date {
  const DATE_OF_CONCILIATION_GENERATION = 16
  const currentDate = new Date(Date.now())
  const dayOfMonth = currentDate.getDate()
  return dayOfMonth >= DATE_OF_CONCILIATION_GENERATION
    ? getNextMonth(currentDate)
    : getCurrentMonth(currentDate)
}

function referenceToRequest(referenceDate) {
  return {
    year: moment(referenceDate).format('YYYY'),
    month: moment(referenceDate).format('MM'),
  }
}

function useReferenceDate(useState, initDate?: string) {
  const init = initDate ? moment(initDate).toDate() : getInitialDate()
  const [referenceDate, setReferenceDate] = useState(init)

  return [
    referenceDate,
    (date: Date) => setReferenceDate(date || getInitialDate()),
    () => referenceToRequest(referenceDate),
    () => moment(referenceDate).format('YYYY-MM-DD'),
  ]
}

export { useReferenceDate }
