import React from 'react'
import { Typography } from '@creditas/typography'
import { withTranslation, WithTranslation } from '@creditas/i18n'

import { envVars } from '../../envVars'
import { noticeError } from '../../helpers'

class ErrorBoundary extends React.Component<WithTranslation> {
  readonly state = { hasError: false }

  constructor(props) {
    super(props)
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })
    noticeError(error)
  }

  render({ t } = this.props) {
    const { hasError } = this.state

    return hasError ? (
      <Typography variant="h1">{t('phrase.error')}</Typography>
    ) : (
      this.props.children
    )
  }
}

const TranslatedErrorBoundary = withTranslation()(ErrorBoundary)

export { TranslatedErrorBoundary }
