import { gql, useQuery } from '@creditas/apollo'

import { GetConciliationDetailsResponse } from '../types'

const deductions = `{
  id
  contractExternalId
  contractType
  contractStatus
  requestDate
  companyName
  companyDocument
  companyCode
  partnerCode
  clientName
  clientDocument
  installmentNumber
  numberOfInstallments
  dueDate
  installmentAmount
  loanAmount
  code
  situation
  status
  reason
  splitPayrollDeduction {
    companyPartialDiscountAmount
    reason
    status
    totalInstallmentAmount
    type
  }
}`

export const CONCILIATION = gql`
  query getConciliationDetails($id: String!) {
    conciliation(id: $id) {
      totalAmount
      referenceMonth
      referenceYear
      conciliationFileUrl
      status
      conciliationGroup {
        id
        name
        companies {
          id
          documentNumber
          documentType
          code
          name
        }
      }
      deductionsNew${deductions}
      deductionsContested${deductions}
      deductionsRefused${deductions}
    }
  }
`
const useGetConciliation = (id: string) => {
  return useQuery<GetConciliationDetailsResponse>(CONCILIATION, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  })
}

export { useGetConciliation }
