import { useTranslation } from '@creditas/i18n'
import { useRouteMatch } from 'react-router-dom'
import React, { Fragment, useState, useEffect, useContext } from 'react'
import moment from 'moment'

import { Container } from '@creditas/layout'
import { Tabs, TabItem } from '@creditas/tabs'
import { Typography } from '@creditas/typography'
import { File, Alert, Action } from '@creditas/icons'

import { Spinner } from '@creditas/spinner'
import { searchInList, emitEvent } from '../../helpers'
import {
  DownloadButton,
  XlsxUnavailable,
  CustomTag,
  Header,
} from './ListInstallments.style'
import { CONCILIATION_STATUS, PayrollDeduction } from '../../services/bff/types'
import {
  useGetConciliation,
  getInvoicesDetails,
} from '../../services/bff/queries'
import { FilterRow, FilterInput } from './ListInstallments.style'
import {
  Card,
  DetailsHeader,
  PayrollDiscountTable,
  InstallmentsTotalizer,
  ChangeConciliation,
  ManagerConciliationActions,
  isActionsDisabled,
} from '../../components'
import { ConciliatorPropsContext, CustomPropsContext } from '../../contexts'
import withTracker from '../../components/Tracer/withTracker'
import {
  AnalyticsActions,
  isAdministrator,
  isCompany,
  ListInstallmentsTabType,
} from '../../interfaces'
import { downloadFile } from '../../helpers/downloadFile'
import { ConfirmConciliation } from '../../components/ConfirmConciliation'
import { useBasePath, useDebounce, useSearchParams } from '../../hooks'
import { MessageBox } from '../ListGroups/ListGroups.style'
import { ConciliationAlertMessage } from '../../components/ConciliationAlertMessage'
import { HelpLink } from '../../components/HelpLink'
import { InvoiceList } from '../../components/InvoiceList/InvoiceList'
import { RestoreConciliationStatusModal } from '../../components/RestoreConciliationStatusModal/RestoreConciliationStatusModal'

interface RouteParams {
  id: string
  tab: ListInstallmentsTabType
}

const ListInstallments = () => {
  const { role } = useContext(ConciliatorPropsContext) || { role: '' }
  const customProps = useContext(CustomPropsContext)
  const basePath = useBasePath()
  const { t } = useTranslation()
  const {
    params: { id, tab: urlTab },
  } = useRouteMatch<RouteParams>()
  const { navigatePreservingParams } = useSearchParams()

  const [filter, setFilter] = useState('')
  const [editionMode, setEditionMode] = useState(false)
  const [showXlsxUnavailable, setShowXlsxUnavailable] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [tab, setTab] = useState<ListInstallmentsTabType>(urlTab || 'new')
  const [installments, setInstallments] = useState<PayrollDeduction[]>([])
  const [reset, setReset] = useState(false)
  const debouncedFilter = useDebounce(filter, 500)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [getInvoices, { data: invoicesData }] = getInvoicesDetails()

  const queryResult = useGetConciliation(id)
  const { data, loading, error, refetch } = queryResult
  const event = (action: string) => {
    emitEvent(customProps, {
      value: installments.length.toString(),
      action,
    })
  }

  useEffect(() => {
    if (data?.conciliation) {
      setInstallments(composeInstallments())
      xlsxAvailability(data?.conciliation.conciliationFileUrl)
      getInvoices({
        variables: {
          year: data.conciliation.referenceYear.toString(),
          month: data.conciliation.referenceMonth.toString(),
          documentNumbers: data?.conciliation.conciliationGroup.companies?.map(
            company => company.documentNumber,
          ),
        },
      })
    }
  }, [data])

  useEffect(() => {
    if (filter.trim()) {
      const filteredInstallments = searchInList(filter, composeInstallments())
      setInstallments(filteredInstallments)
    } else if (data) {
      setInstallments(composeInstallments())
    }
  }, [debouncedFilter, tab])

  function xlsxAvailability(conciliationFileUrl?: string) {
    setShowXlsxUnavailable(!conciliationFileUrl)
    if (!conciliationFileUrl) {
      event(AnalyticsActions.XlsxUnavailable)
    }
  }

  function handleChangeConciliation(newEditionMode: boolean) {
    const conciliationIsApproved =
      data?.conciliation.status === CONCILIATION_STATUS.APPROVED
    const canReopenConciliation =
      isAdministrator(role) && conciliationIsApproved

    canReopenConciliation
      ? setIsModalOpen(true)
      : setEditionMode(newEditionMode)
  }

  function onCloseRestoreStatusModal(confirmed: boolean) {
    setIsModalOpen(false)
    if (confirmed) {
      refetch()
      setEditionMode(true)
    }
  }

  function onSelected(items: string[]) {
    setSelectedItems(items as any)
  }

  async function downloadConciliationSpreadsheet() {
    event(AnalyticsActions.DownloadConciliationRequested)
    if (!data?.conciliation.conciliationFileUrl) return
    downloadFile(data?.conciliation.conciliationFileUrl, '')
    event(AnalyticsActions.DownloadConciliationCompleted)
  }

  function updateRequest() {
    setReset(true)
    refetch()
    setSelectedItems([])
  }

  useEffect(() => {
    if (reset) {
      setReset(false)
    }
  }, [reset])

  function composeInstallments(): PayrollDeduction[] {
    if (!data) return []
    const { conciliation } = data
    switch (tab) {
      case 'contested':
        return conciliation.deductionsContested
      case 'refused':
        return conciliation.deductionsRefused
    }
    return conciliation.deductionsNew
  }

  function navigate(tab: ListInstallmentsTabType) {
    setSelectedItems([])
    setTab(tab)
    navigatePreservingParams(`${basePath}${t('routes.companies')}/${id}/${tab}`)
    setFilter('')
  }

  function tabIsActive(compare: ListInstallmentsTabType) {
    return tab === compare ? 'selected' : undefined
  }

  function enableEditModeForRole(): boolean {
    if (isCompany(role)) {
      return !!(tabIsActive('new') && editionMode)
    } else {
      return editionMode
    }
  }

  function enableByTag(): boolean {
    return !tabIsActive('new')
  }

  function installmentsLength(): number {
    return (
      (data?.conciliation.deductionsNew.length || 0) +
      (data?.conciliation.deductionsContested.length || 0)
    )
  }

  function selectedDeductions(selectedItems: string[]): PayrollDeduction[] {
    return installments.filter(i => selectedItems.indexOf(i.id) > -1)
  }

  function conciliationDate() {
    if (data?.conciliation) {
      return moment(
        `${data?.conciliation.referenceYear}-${data?.conciliation.referenceMonth}-01`,
      ).format('YYYY-MM-DD')
    } else {
      return ''
    }
  }

  function disableChangeConciliation() {
    if (loading) {
      return true
    }

    if (isAdministrator(role)) {
      return false
    }

    return isActionsDisabled(data?.conciliation.status!)
  }

  return (
    <Fragment>
      <DetailsHeader
        referenceYear={data?.conciliation.referenceYear}
        referenceMonth={data?.conciliation.referenceMonth}
        groupName={data?.conciliation.conciliationGroup.name}
      />
      <Container>
        <ConciliationAlertMessage status={data?.conciliation.status!} />
        <Header>
          <Typography variant="h4">{t('headings.read')}</Typography>
          <HelpLink anchorId={'lp-pom-text-1619'} />
        </Header>
        <InstallmentsTotalizer
          amount={data?.conciliation.totalAmount}
          length={installmentsLength()}
        >
          <ChangeConciliation
            id={id}
            onChange={handleChangeConciliation}
            disabled={disableChangeConciliation()}
            editing={editionMode}
          />
          <RestoreConciliationStatusModal
            id={id}
            isOpen={isModalOpen}
            onClose={onCloseRestoreStatusModal}
          />
          <ConfirmConciliation
            onConfirm={refetch}
            editing={editionMode}
            status={data?.conciliation.status!}
          />
        </InstallmentsTotalizer>
        <InvoiceList
          invoiceList={invoicesData?.invoiceDetails!}
          groupName={data?.conciliation.conciliationGroup.name || ''}
          referenceDate={`${data?.conciliation.referenceYear}-${data?.conciliation.referenceMonth}`}
        />
        <Card>
          {showXlsxUnavailable && (
            <XlsxUnavailable variant="info">
              <Typography variant="paragraph">
                {t('alerts.xlsx.unavailable')}
              </Typography>
            </XlsxUnavailable>
          )}
          <FilterRow>
            <Tabs>
              <TabItem
                onClick={() => navigate('new')}
                variant={tabIsActive('new')}
                data-testid="new-tab"
              >
                {t('labels.tabs.discountFromPayroll')}
                <CustomTag
                  label={data?.conciliation.deductionsNew.length.toString()}
                />
              </TabItem>
              <TabItem
                onClick={() => navigate('contested')}
                variant={tabIsActive('contested')}
                data-testid="contested-tab"
              >
                {t('labels.tabs.underAnalysis')}
                <CustomTag
                  label={data?.conciliation.deductionsContested.length.toString()}
                />
              </TabItem>
              <TabItem
                onClick={() => navigate('refused')}
                variant={tabIsActive('refused')}
                data-testid="refused-tab"
              >
                {t('labels.tabs.dontDiscountFromPayroll')}
                <CustomTag
                  label={data?.conciliation.deductionsRefused.length.toString()}
                />
              </TabItem>
            </Tabs>
            <DownloadButton
              variant="secondary"
              isLoading={false}
              endIcon={<File.DownloadCloud />}
              title={t('labels.downloadConciliation')}
              disabled={!data?.conciliation.conciliationFileUrl}
              onClick={downloadConciliationSpreadsheet}
              target={'blank'}
              data-testid="download-installments"
            >
              {t('labels.downloadXlsx')}
            </DownloadButton>
            <FilterInput
              size="comfortable"
              label={t('labels.filterInstallments')}
              value={filter}
              onChange={({ target }) => setFilter(target.value)}
            />
          </FilterRow>

          {loading ? (
            <MessageBox>
              <Spinner message={t('labels.loadingInstallments')} />
            </MessageBox>
          ) : error ? (
            <MessageBox>
              <Action.XCircle fontSize="large" color="danger" />
              <Typography variant="paragraph">
                {t('labels.loadingInstallmentsError')}
              </Typography>
            </MessageBox>
          ) : !installments.length ? (
            <MessageBox>
              <Alert.AlertCircle fontSize="large" color="info" />
              <Typography variant="paragraph">
                {t('labels.emptyInstallments')}
              </Typography>
            </MessageBox>
          ) : (
            <>
              <ManagerConciliationActions
                onSubmitted={updateRequest}
                enabledEditionMode={editionMode}
                selectedItems={selectedItems}
                currentTab={tab}
              />
              <PayrollDiscountTable
                reset={reset}
                tab={tab}
                enabledEditionMode={enableEditModeForRole()}
                enabledReason={enableByTag()}
                payrollDiscounts={installments}
                onSelected={onSelected}
              />
            </>
          )}
        </Card>
      </Container>
    </Fragment>
  )
}

export default withTracker(ListInstallments)
