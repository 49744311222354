import { PAGINATION_DEFAULT } from '../components/Pagination'

export function paginationRequest({ page, limit }) {
  const newPage = page - 1
  return {
    page: newPage < 0 ? 0 : newPage,
    limit: limit < PAGINATION_DEFAULT.limit ? PAGINATION_DEFAULT.limit : limit,
  }
}

export function pageResponse(page) {
  return page ? page + 1 : 1
}
