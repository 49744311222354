import React, { useContext } from 'react'
import { useTranslation } from '@creditas/i18n'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import { CustomPropsContext } from './contexts/CustomPropsContext'
import ListGroups from './pages/ListGroups/ListGroups'
import UploadSpreadsheet from './pages/UploadSpreadsheet/UploadSpreadsheet'
import ListInstallments from './pages/ListInstallments/ListInstallments'

export const Routes = () => {
  const { t } = useTranslation()
  const { productBasePath } = useContext(CustomPropsContext)
  return (
    <BrowserRouter>
      <Switch>
        <Route
          component={ListGroups}
          exact
          path={productBasePath + t('routes.companies')}
        />
        <Route
          component={ListInstallments}
          path={productBasePath + t('routes.companyInstallments')}
        />
        <Route
          component={UploadSpreadsheet}
          path={productBasePath + t('routes.upload')}
        />
        <Route
          path={productBasePath}
          component={() => (
            <Redirect to={productBasePath + t('routes.companies')} />
          )}
        />
      </Switch>
    </BrowserRouter>
  )
}
