import React, { MouseEvent, useCallback } from 'react'
import { useTranslation } from '@creditas/i18n'

import { Table, TableCell, TableBody } from '@creditas/table'

import { TableHead } from '..'
import { CompaniesTableProps } from '.'
import { SortBy } from '../SortBy'
import { formatCurrency, getStatus } from '../../helpers'
import { TableRow } from './GroupsTable.style'
import { Tag } from '@creditas/tag'

export const GroupsTable: React.FC<Partial<CompaniesTableProps>> = ({
  companies = [],
  onClick = () => {},
}) => {
  const { t } = useTranslation()

  const handleClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
    onClick(event.currentTarget.dataset.id || '')
  }, [])

  return (
    <Table fullWidth data-testid="group-table">
      <TableHead>
        <TableRow>
          <TableCell textAlign="left">
            <SortBy columnName="groupName" align="left">
              {t('labels.group.name')}
            </SortBy>
          </TableCell>
          <TableCell>
            <SortBy columnName="deductionsAmount">
              {t('labels.group.totalAmount')}
            </SortBy>
          </TableCell>
          <TableCell>
            <SortBy columnName="deductionsQuantity">
              {t('labels.group.numberOfInstallments')}
            </SortBy>
          </TableCell>
          <TableCell>
            <SortBy columnName="status">{t('labels.group.status')}</SortBy>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody variant="striped">
        {companies.map(({ id, conciliationGroup, ...conciliation }) => (
          <TableRow key={id} data-id={id} onClick={handleClick}>
            <TableCell>{conciliationGroup?.name}</TableCell>
            <TableCell textAlign="center">
              {formatCurrency(t('locale'), conciliation?.totalAmount)}
            </TableCell>
            <TableCell textAlign="center">
              {conciliation?.numberOfInstallments}
            </TableCell>
            <TableCell textAlign="center">
              <Tag
                label={t(`conciliationStatus.${conciliation?.status}`, {
                  defaultValue: conciliation?.status,
                })}
                variant={getStatus(conciliation?.status)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
