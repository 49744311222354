import { AppCustomProps } from '../index'

const ga = () => window['ga'] || function(...args) {}
const dataLayer = () => window['dataLayer'] || (window['dataLayer'] = [])

export interface EventAnalytics {
  action: string
  value: string
}

export interface PageViewAnalytics {
  path: string
  title: string
}

const pushEvent = ({ userType }: AppCustomProps, value: EventAnalytics) => {
  const data = {
    event: 'gaCreditasPayrollAdmin',
    category: 'conciliation',
    label: userType,
    ...value,
  }
  dataLayer().push(data)
}

function emitPageView(
  { userType }: AppCustomProps,
  value?: PageViewAnalytics,
): void {
  value = value || {
    path: window.location.href,
    title: document.title,
  }
  dataLayer().push({
    event: 'creditas.pageview',
    label: userType,
    ...value,
  })
  ga()('send', 'pageview', value.path)
}

export { pushEvent, emitPageView }
