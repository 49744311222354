import { useTranslation } from '@creditas/i18n'
import React, { useContext, useState } from 'react'
import { RestoreDeductionProps } from '.'
import { useConfirmAction } from '../../hooks'
import { Typography } from '@creditas/typography'
import { SelectReason, ACTION, selectReasons } from '../SelectReason'
import { Actions, ButtonCompact } from '../Form'
import { useRestoreDeduction } from '../../services/bff/mutations'
import { emitEvent } from '../../helpers'
import { CustomPropsContext } from '../../contexts'
import { AnalyticsActions } from '../../interfaces'

export const RestoreDeduction = (props: RestoreDeductionProps) => {
  let { selectedItems = [], onSubmitted } = props
  const { t } = useTranslation()
  const [reason, setReason] = useState('')
  const customProps = useContext(CustomPropsContext)
  const [submitted, setSubmitted] = useState(false)
  const { confirm } = useConfirmAction()
  const [restoreDeduction, { loading }] = useRestoreDeduction()

  const event = (action: string) =>
    emitEvent(customProps, {
      value: reason,
      action,
    })

  function inputInvalid(): boolean {
    return submitted && !reason
  }

  function changeReason(reason: string) {
    setReason(reason)
  }

  const askConfirmation = () => {
    setSubmitted(true)
    if (isFormValid()) {
      confirm({
        ask: {
          title: t('alerts.restoreDeduction.title'),
          text: t('alerts.restoreDeduction.text'),
          action: t('alerts.restoreDeduction.action'),
        },
        success: t('alerts.restoreDeduction.success'),
        error: t('alerts.restoreDeduction.error'),
        loading: t('alerts.restoreDeduction.loading'),
        confirm: success,
      })
    } else {
      event(AnalyticsActions.RestoreConciliationSubmitInvalid)
    }
  }

  function isFormValid() {
    return reason && selectedItems.length > 0
  }

  const success = async () => {
    return new Promise((res, rej) => {
      const restorePayrollDeductions = selectedItems.map(id => ({
        id,
        reason,
      }))
      event(AnalyticsActions.RestoreConciliationReasonRequested)
      restoreDeduction({
        variables: { restorePayrollDeductions },
      })
        .then(r => {
          res(r)
          reset()
          event(AnalyticsActions.RestoreConciliationReasonCompleted)
          onSubmitted()
        })
        .catch(e => {
          rej(e)
          reset()
        })
    })
  }

  function reset() {
    setReason('')
    setSubmitted(false)
    selectedItems = []
  }

  function itemsNotSelected(): boolean {
    return submitted && !selectedItems.length
  }

  return (
    <Actions>
      <SelectReason
        data-testid={'selectActions'}
        value={reason}
        {...selectReasons(ACTION.INCLUDE_REASONS)}
        invalid={inputInvalid()}
        onChange={changeReason}
      />
      <ButtonCompact
        onClick={askConfirmation}
        isLoading={loading}
        disabled={loading}
      >
        {t('labels.restoreFromConciliation', { length: selectedItems.length })}
      </ButtonCompact>
      {itemsNotSelected() && (
        <Typography color="danger">
          {t('labels.deductionNotSelected')}
        </Typography>
      )}
    </Actions>
  )
}
