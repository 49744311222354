import React, { useContext, useState } from 'react'

import { useTranslation } from '@creditas/i18n'
import { Button } from '@creditas/button'
import { Typography } from '@creditas/typography'
import { Tag } from '@creditas/tag'
import { Table, TableBody, TableCell, TableRow } from '@creditas/table'
import { Card } from '..'

import { TableHead, CardHeader } from './InvoiceList.styles'
import AccordionIcon from './AccordionIcon'

import { InvoiceDetail } from '../../services/bff/types'
import { formatCurrency, formatDate } from '../../helpers'
import { emitEvent } from '../../helpers'
import { CustomPropsContext } from '../../contexts'
import { AnalyticsActions } from '../../interfaces'

interface Props {
  invoiceList: InvoiceDetail[]
  groupName: string
  referenceDate: string
}

const InvoiceList = ({ invoiceList = [], groupName, referenceDate }: Props) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const { t } = useTranslation()
  const locale = t('locale')
  const customProps = useContext(CustomPropsContext)

  const onDownloadFile = (path: string) => {
    event(
      AnalyticsActions.DownloadInvoiceRequested,
      `${referenceDate}-${groupName}`,
    )
    window.location.assign(`${path}.pdf?download=true`)
  }

  const event = (action: AnalyticsActions, value = '') =>
    emitEvent(customProps, {
      action,
      value,
    })

  const statusTag = (paidAt: string | null, dueDate: string) => {
    if (paidAt) return <Tag label="Pago" variant="success" />

    const now = new Date()
    const dueDateAsDate = new Date(dueDate)

    return now > dueDateAsDate ? (
      <Tag label="Vencido" variant="danger" />
    ) : (
      <Tag label="Pendente" />
    )
  }

  const handleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  return invoiceList.length > 0 ? (
    <Card>
      <CardHeader onClick={handleAccordion}>
        <Typography variant="h6">Boletos</Typography>
        {invoiceList.length > 1 ? (
          <AccordionIcon isExpanded={isExpanded} />
        ) : null}
      </CardHeader>
      {invoiceList.length === 1 || isExpanded ? (
        <Table fullWidth style={{ marginTop: 20 }}>
          <TableHead>
            <TableRow>
              <TableCell textAlign="left">Valor</TableCell>
              <TableCell textAlign="left">CNPJs inclusos</TableCell>
              <TableCell textAlign="left">Criado em</TableCell>
              <TableCell textAlign="left">Vencimento em</TableCell>
              <TableCell textAlign="left">Status</TableCell>
              <TableCell textAlign="center">Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceList?.map(invoiceDetail => (
              <TableRow key={invoiceDetail.id}>
                <TableCell>
                  {formatCurrency(locale, parseFloat(invoiceDetail.amount))}
                </TableCell>
                <TableCell>
                  {invoiceDetail.companiesDocumentNumber.replace(/\,/g, ';')}
                </TableCell>
                <TableCell>
                  {formatDate(locale, invoiceDetail.createdAt)}
                </TableCell>
                <TableCell>
                  {formatDate(locale, invoiceDetail.dueDate)}
                </TableCell>
                <TableCell>
                  {statusTag(invoiceDetail.paidAt, invoiceDetail.dueDate)}
                </TableCell>
                <TableCell textAlign="center">
                  <Button
                    variant="primary"
                    onClick={() => onDownloadFile(invoiceDetail.link)}
                  >
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}
    </Card>
  ) : (
    <></>
  )
}

export { InvoiceList }
