import { styled } from '@creditas/stylitas'
import { Card as _Card } from '@creditas/card'
import { TableHead as _TableHead } from '@creditas/table'

export * from './DateSelect/DateSelect'
export * from './ErrorBoundary/ErrorBoundary'
export * from './DetailsHeader/DetailsHeader'
export * from './GroupsTable/GroupsTable'
export * from './PayrollDiscountTable/PayrollDiscountTable'
export * from './InstallmentsTotalizer/InstallmentsTotalizer'
export * from './ChangeConciliation'
export * from './ContestDeduction'
export * from './RemoveDeduction'
export * from './ManagerConciliationActions'
export * from './ConfirmConciliation'
export * from './CustomTag/CustomTag'
export * from './Form'
export * from './SelectReason'

export const Card = styled(_Card)`
  margin: 20px auto;

  &:hover {
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    box-shadow: none;
  }
`

export const TableHead = styled(_TableHead)`
  font-weight: bold;
`
