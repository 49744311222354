import { styled } from '@creditas/stylitas'
import { Select as SelectBase } from '../Form/Select.styled'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
`

export const Select = styled(SelectBase)`
  margin-right: 1rem;
  margin-bottom: 0;
  width: 200px;
`
