import { gql, useQuery } from '@creditas/apollo'

import { GetConciliatorResponse } from './../types'

export const CONCILIATOR = gql`
  query {
    conciliator {
      id
      email
      name
      role
    }
  }
`

export const useGetConciliator = () => {
  return useQuery<GetConciliatorResponse>(CONCILIATOR)
}
