import { TableRow } from '@creditas/table'
import { css, utilities, styled } from '@creditas/stylitas'

const backgroundColor = ({ theme }) => css`
  background-color: ${utilities.palette.color(theme, 'neutral03')};
`

const TableRowClickable = styled(TableRow)`
  &:hover td {
    ${backgroundColor}
  }
  td {
    cursor: pointer;
  }
`

const borderRadius = ({ theme }) => css`
  ${utilities.radius.small({ theme })};
`

const StatusWrapper = styled.div`
  ${borderRadius}
  ${backgroundColor}
  padding: 4px;
`

export { StatusWrapper, TableRowClickable as TableRow }
