import { useToaster } from '@creditas/toaster'
import { Context } from '../components/ConfirmConciliation/ConfirmConciliation.style'
import { Typography } from '@creditas/typography'
import React from 'react'

export interface Alert {
  title: string
  text?: string
  level?: 'info' | 'success' | 'warning' | 'danger'
  delay?: number
}

const Error = ({ title, text }: Alert) => (
  <Context>
    <Typography variant="form">{title}</Typography>
    <Typography variant="support">{text}</Typography>
  </Context>
)

function useAlert() {
  const { push } = useToaster()
  const alert = (props: Alert) => {
    const { title, text, level = 'info', delay = 2000 } = props
    push(title && text ? Error({ title, text }) : title, {
      level,
      delay,
    })
  }
  return { alert }
}

export { useAlert }
