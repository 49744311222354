import { AnalyticsActions } from '../../interfaces'

export * from './SelectReason'
export * from './SelectReasonFactory'

export enum REASONS {
  TERMINATION = 'termination',
  RETIRED = 'temporarilyRetired',
  LIMIT = 'limit',
  NOT_DEDUCTED = 'notDeducted',
  MATERNITY_LEAVE = 'maternityLeave',
  VACATION = 'vacation',
  INCORRECT_EMPLOYEE = 'incorrectEmployee',
  WRONG_TERMINATION = 'wrongTermination',
  DEDUCTED = 'deducted',
  OTHERS = 'others',
}

export enum LABEL {
  REMOVE = 'labels.selectRemoveConciliation',
  INCLUDE = 'labels.selectRestoreConciliation',
  CONTEST = 'labels.selectContestConciliation',
}

export interface SelectReasonProps {
  value?: string
  invalid?: boolean
  disabled?: boolean
  analytics: AnalyticsActions
  options?: REASONS[]
  onChange: (value: string) => any
  label: LABEL
}

export enum ACTION {
  REMOVE_REASONS,
  CONTEST_REASONS,
  INCLUDE_REASONS,
}

export interface SelectReasonFactory {
  analytics: AnalyticsActions
  options: REASONS[]
  label: LABEL
}
