import { CONCILIATION_STATUS } from '../../services/bff/types'

export interface ConfirmConciliationProps {
  onConfirm(): void
  editing?: boolean
  status?: CONCILIATION_STATUS
}

export const isActionsDisabled = (status: CONCILIATION_STATUS) => {
  return status === CONCILIATION_STATUS.APPROVED
}

export const isConfirmationDisabled = (status: CONCILIATION_STATUS) => {
  return status !== CONCILIATION_STATUS.PENDING
}

export * from './ConfirmConciliation'
