import React, { Component } from 'react'
import { emitPageView } from '../../helpers'
import { CustomPropsContext } from '../../contexts'

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

const withTracker = WrappedComponent => {
  const factory = class extends Component {
    static context = CustomPropsContext
    static displayName: string
    componentDidMount() {
      emitPageView(this.context)
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  factory.displayName = `withTracker(${getDisplayName(WrappedComponent)})`
  return factory
}

export default withTracker
