import { styled } from '@creditas/stylitas'
import { Button as B } from '@creditas/button'

export const Button = styled(B)`
  margin-right: 1rem;
`

export const ButtonCompact = styled(B)`
  min-width: 0;
  margin-right: 1rem;
`
