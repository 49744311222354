import { gql, useMutation } from '@creditas/apollo'

import { GetConciliationDetailsResponse } from '../types'

export const CONFIRM_CONCILIATION = gql`
  mutation confirmConciliation($id: ID!) {
    confirmConciliation(id: $id) {
      id
      status
      referenceDate
      totalAmount
      installmentAmount
      approvals {
        id
        approvedAt
        conciliator {
          id
          name
          email
          externalId
          role
          conciliationGroups {
            id
            name
            headquartersCompany {
              name
              document
            }
            companies {
              name
              documentNumber
              documentType
            }
          }
        }
      }
      conciliationGroup {
        id
        name
        headquartersCompany {
          name
          document
        }
        companies {
          name
          documentNumber
          documentType
        }
      }
    }
  }
`

const useConfirmConciliation = (id: string) => {
  return useMutation(CONFIRM_CONCILIATION, {
    variables: { id },
  })
}

export { useConfirmConciliation }
