import React from 'react'

import { Button } from '@creditas/button'
import { Navigation } from '@creditas/icons'
import { Container } from '@creditas/layout'
import { Typography } from '@creditas/typography'

import { DetailsHeaderProps } from '.'
import { capitalize } from '../../helpers'
import { Header } from './DetailsHeader.style'
import { useRoute, useSearchParams } from '../../hooks'

const DetailsHeader = ({
  goBack,
  groupName = '',
  referenceYear,
  referenceMonth,
}: DetailsHeaderProps) => {
  const companiesRoute = useRoute('companies')
  const { navigatePreservingParams } = useSearchParams()

  const month = String(referenceMonth).padStart(2, '0')
  const monthDate = new Date(`${referenceYear}-${month}-01T00:00`)
  const monthName = capitalize(
    monthDate.toLocaleDateString('pt-BR', { month: 'long' }),
  )
  return (
    <Header>
      <Container>
        <Button
          children=" "
          onClick={goBack ?? (() => navigatePreservingParams(companiesRoute))}
          variant="tertiary"
          endIcon={<Navigation.ArrowLeft />}
        />
        <div>
          <Typography variant="h6" data-testid={'title'}>
            {groupName}
          </Typography>
        </div>
        <div>
          {referenceMonth && referenceYear && (
            <Typography variant="h6">
              {monthName} de {referenceYear}
            </Typography>
          )}
        </div>
      </Container>
    </Header>
  )
}

export { DetailsHeader }
