import { useTranslation } from '@creditas/i18n'
import React, { useState, useEffect } from 'react'

import { Spinner } from '@creditas/spinner'
import { Container } from '@creditas/layout'
import { Alert, Action } from '@creditas/icons'
import { Typography } from '@creditas/typography'

import { GroupsTable, Card } from '../../components'
import { Header, MessageBox } from './ListGroups.style'

import {
  useBasePath,
  useDebounce,
  usePagination,
  useReferenceDate,
  useSearchParams,
} from '../../hooks'
import { SortByEvent, SortContext } from '../../components/SortBy'
import { useGetConciliations } from '../../services/bff/queries'
import { Pagination, PaginationEvents } from '../../components/Pagination'
import withTracker from '../../components/Tracer/withTracker'
import { FilterListGroups } from '../../components/FilterListGroups'
import { pageResponse, paginationRequest } from '../../helpers'
import { HelpLink } from '../../components/HelpLink'

const ListGroups = () => {
  const { t } = useTranslation()
  const basePath = useBasePath()
  const {
    replaceParams,
    getSearchParams,
    navigatePreservingParams,
  } = useSearchParams()
  const {
    orderBy: queryOrderBy,
    asc: queryAsc,
    groupName: queryGroupName,
    status: queryStatus,
    referenceDate: queryReferenceDate,
  } = getSearchParams()

  const [pagination, setPagination] = usePagination(useState)
  const [asc, setAsc] = useState<boolean>(queryAsc ? queryAsc === 'true' : true)
  const [orderBy, setOrderBy] = useState<string>(queryOrderBy || '')
  const [
    referenceDate,
    setReferenceDate,
    referenceDateToRequest,
    referenceDateToString,
  ] = useReferenceDate(useState, queryReferenceDate)
  const [groupName, setGroupName] = useState(queryGroupName || '')
  const [status, setStatus] = useState(queryStatus || '')

  const debouncedGroupName = useDebounce(groupName, 500)

  const { data, loading, error } = useGetConciliations({
    ...referenceDateToRequest(),
    ...paginationRequest(pagination),
    groupName: debouncedGroupName,
    status,
    orderBy,
    asc,
  })

  useEffect(() => {
    setPagination({ ...pagination })
  }, [referenceDate, status, debouncedGroupName])

  useEffect(() => {
    replaceParams({
      referenceDate: referenceDateToString(),
      groupName,
      status,
      orderBy,
      asc: asc.toString(),
      page: pagination.page.toString(),
      limit: pagination.limit.toString(),
    })
  }, [referenceDate, status, debouncedGroupName, pagination, orderBy, asc])

  function navigateToConciliationInstallments(id: string) {
    navigatePreservingParams(`${basePath}${t('routes.companies')}/${id}/new`)
  }

  function pageChange(e: PaginationEvents) {
    setPagination(e)
  }

  function handleSort({ sortBy, direction }: SortByEvent) {
    setAsc(direction === 'asc')
    setOrderBy(sortBy)
  }

  return (
    <Container>
      <Card>
        <Header>
          <Typography variant="h5">{t('headings.read')}</Typography>
          <HelpLink anchorId={'lp-pom-text-1130'} />
          <FilterListGroups
            stateReferenceDate={[referenceDate, setReferenceDate]}
            stateGroupName={[groupName, setGroupName]}
            stateStatus={[status, setStatus]}
          />
        </Header>
        {loading ? (
          <MessageBox>
            <Spinner message={t('labels.loadingConciliations')} />
          </MessageBox>
        ) : error ? (
          <MessageBox>
            <Action.XCircle fontSize="large" color="danger" />
            <Typography variant="paragraph">
              {t('labels.loadingConciliationsError')}
            </Typography>
          </MessageBox>
        ) : !data?.conciliations.data.length ? (
          <MessageBox>
            <Alert.AlertCircle fontSize="large" color="info" />
            <Typography variant="paragraph">
              {t('labels.emptyConciliations')}
            </Typography>
          </MessageBox>
        ) : (
          <SortContext onChangeSort={handleSort}>
            <GroupsTable
              companies={data?.conciliations.data}
              onClick={navigateToConciliationInstallments}
            />
          </SortContext>
        )}
        <Pagination
          total={data?.conciliations.total}
          limit={data?.conciliations.limit}
          page={pageResponse(data?.conciliations.page)}
          onChange={pageChange}
        />
      </Card>
    </Container>
  )
}

export default withTracker(ListGroups)
