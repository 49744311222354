export enum AnalyticsActions {
  DownloadInvoiceRequested = 'downloadInvoiceRequested',
  DownloadConciliationRequested = 'downloadConciliationRequested',
  DownloadConciliationCompleted = 'downloadConciliationCompleted',
  TerminationRequestCloseModal = 'terminationRequestCloseModal',
  TerminationConfirmCloseModal = 'terminationConfirmCloseModal',
  TerminationCancelCloseModal = 'terminationCancelCloseModal',
  TerminationShouldPayrollDiscount = 'terminationShouldPayrollDiscount',
  ConfirmConciliationAsk = 'confirmConciliationAsk',
  ConfirmConciliationRequested = 'confirmConciliationRequested',
  ConfirmConciliationCompleted = 'confirmConciliationCompleted',
  XlsxUnavailable = 'xlsxLinkUnavailable',
  InitEditMode = 'initEditMode',
  ExitEditMode = 'exitEditMode',
  RemoveConciliationReason = 'removeConciliationReason',
  RemoveConciliationReasonRequested = 'removeConciliationRequested',
  RemoveConciliationReasonCompleted = 'removeConciliationCompleted',
  RemoveConciliationSubmitInvalid = 'removeConciliationSubmitInvalid',
  ContestConciliationReason = 'contestConciliationReason',
  ContestConciliationReasonRequested = 'contestConciliationRequested',
  ContestConciliationReasonCompleted = 'contestConciliationCompleted',
  ContestConciliationSubmitInvalid = 'contestConciliationSubmitInvalid',
  RestoreConciliationReason = 'restoreConciliationReason',
  RestoreConciliationReasonRequested = 'restoreConciliationRequested',
  RestoreConciliationReasonCompleted = 'restoreConciliationCompleted',
  RestoreConciliationSubmitInvalid = 'restoreConciliationSubmitInvalid',
  ClickConciliationInAnaliseMessage = 'ClickConciliationInAnaliseMessage',
}
