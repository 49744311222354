import { styled } from '@creditas/stylitas'
import { InputField } from '@creditas/form-elements'

export const Field = styled(InputField)`
  margin-bottom: -16px;
  margin-right: 1rem;
  height: 50px;

  legend {
    top: 16px;
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`
