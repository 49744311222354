import { Button } from '@creditas/button'
import { styled } from '@creditas/stylitas'
import { InputField } from '@creditas/form-elements'
import { FeedbackMessage } from '@creditas/feedback-message'
import { CustomTag as C } from '../../components/CustomTag/CustomTag'

export const CustomTag = styled(C)`
  margin-left: 1rem;
`

export const FilterRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  div:first-of-type {
    flex: 1;
  }
`

export const DownloadButton = styled(Button)`
  min-width: 0;
  height: 40px;
  margin-right: 1rem;

  svg {
    font-size: 20px;
    margin-left: 10px;
  }

  &::after {
    left: 50%;
  }
`

export const XlsxUnavailable = styled(FeedbackMessage)`
  margin-bottom: 1rem;
`

export const FilterInput = styled(InputField)`
  margin-bottom: -16px;
  height: 40px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  svg {
    fill: #999;
  }
`
