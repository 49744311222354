import { useHistory } from 'react-router-dom'

export function useSearchParams(url = window.location.href) {
  const history = useHistory()

  function replaceParams(params: { [key: string]: string } = {}) {
    const newURL = new URL(url)
    const paramsEntries = Object.entries(params)
    for (const [param, value] of paramsEntries) {
      newURL.searchParams.set(param, value)
    }
    history.push({ search: newURL.search })
  }

  function navigatePreservingParams(pathname: string) {
    history.push({ pathname, search: location.search })
  }

  function getSearchParams() {
    const newURL = new URL(url)
    const params = newURL.searchParams.entries()
    return Object.fromEntries(params)
  }

  return {
    replaceParams,
    getSearchParams,
    navigatePreservingParams,
  }
}
