export function formatDate(
  locale: string,
  date: number | string | Date,
): string {
  const DATE_REGEX = /^\d{4}-\d{2}-\d{2}/
  if (typeof date === 'string' && DATE_REGEX.test(date)) {
    date = date.slice(0, 10) + 'T00:00:00'
  }
  const newDate = new Date(date)
  return newDate.toLocaleDateString(locale)
}
