import React, { useState } from 'react'
import { Alert } from '@creditas/icons'
import { useTranslation } from '@creditas/i18n'
import { Typography } from '@creditas/typography'

import { useHistory } from 'react-router-dom'
import { DetailsHeader } from '../../components'
import withTracker from '../../components/Tracer/withTracker'
import UploadFileSelector from '../../components/UploadFileSelector/UploadFileSelector'
import { useSearchParams } from '../../hooks'
import { Instructions, PageCard } from './UploadSpreadsheet.style'

function UploadSpreadsheet() {
  const [errors, setErrors] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()
  const { referenceDate } = useSearchParams().getSearchParams()

  const { month, year } =
    referenceDate.match(/(?<year>\d{4})-(?<month>\d{2})/)?.groups ?? {}
  return (
    <div>
      <DetailsHeader
        referenceYear={year}
        referenceMonth={month}
        groupName={t('headings.read')}
        goBack={() => history.goBack()}
      />
      <PageCard>
        {errors ? (
          <Instructions>
            <Typography
              variant="h4"
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 32,
              }}
            >
              <Alert.AlertTriangle color="danger" fontSize="large" />
              <span>Erro na alteração em lote</span>
            </Typography>
            <Typography variant="paragraph" style={{ marginBottom: 32 }}>
              O envio não pôde ser concluído devido a erros no preenchimento da
              planilha. Faça as correções dos erros apontados e envie novamente.
            </Typography>
            <Typography>
              <strong>Possíveis erros na planilha:</strong>
            </Typography>
            <ul>
              <li>
                <Typography>O motivo de contestação é inválido.</Typography>
              </li>
              <li>
                <Typography>
                  Selecione uma das opções existentes. Em contestação de motivo
                  ”outros”, é obrigatório descrever o motivo.
                </Typography>
              </li>
              <li>
                <Typography>
                  Em contestação de motivo ”rescisão”, é obrigatório inserir da
                  data e valor líquido da rescisão.
                </Typography>
              </li>
              <li>
                <Typography>Valor líquido da rescisão é inválido.</Typography>
              </li>
            </ul>
          </Instructions>
        ) : (
          <Instructions>
            <Typography variant="h4" className="title">
              Solicitar alteração em lote
            </Typography>
            <Typography className="subtitle">
              Antes de iniciar, entenda como funciona:
            </Typography>
            <ul>
              <li>
                <Typography>Baixe o modelo da planilha.</Typography>
              </li>
              <li>
                <Typography>
                  Preencha as células com os motivos de contestação{' '}
                  <strong>
                    apenas dos colaboradores que deseja alterar. <br /> Atenção:{' '}
                  </strong>
                  em caso de rescisão e outros motivos, preencha as informações
                  complementares.
                </Typography>
              </li>
              <li>
                <Typography>Salve a planilha e envie.</Typography>
              </li>
            </ul>
          </Instructions>
        )}
        <UploadFileSelector onError={setErrors} />
      </PageCard>
    </div>
  )
}

export default withTracker(UploadSpreadsheet)
