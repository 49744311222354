import { styled } from '@creditas/stylitas'

export const DateSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > div + div {
    flex-basis: 20%;
    margin-left: 10px;
  }
`
