import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from '@creditas/i18n'
import { isAdministrator } from '../../interfaces'
import { ConciliatorPropsContext } from '../../contexts'
import { RemoveDeduction } from '../RemoveDeduction'
import { RestoreDeduction } from '../RestoreDeduction'
import * as S from './ManagerConciliationActions.styled'
import { ManagerConciliationActionsProps } from './'

const ManagerConciliationActions = (props: ManagerConciliationActionsProps) => {
  const {
    selectedItems = [],
    enabledEditionMode = false,
    onSubmitted,
    currentTab,
  } = props
  const { role } = useContext(ConciliatorPropsContext) || { role: '' }
  const { t } = useTranslation()
  const [action, setAction] = useState<'remove' | 'restore' | ''>('')
  const options = [
    {
      text: t('labels.removeDeduction'),
      value: 'remove',
    },
    {
      text: t('labels.restoreDeduction'),
      value: 'restore',
    },
  ]

  useEffect(() => {
    switch (currentTab) {
      case 'new':
        setAction('remove')
        break
      case 'refused':
        setAction('restore')
        break
    }
  }, [currentTab])

  function showComponent() {
    return isAdministrator(role) && enabledEditionMode
  }

  function renderStrategy() {
    switch (action) {
      case 'remove':
        return renderRemoveDeduction()
      case 'restore':
        return renderRestoreDeduction()
    }
  }

  function selectReason(e) {
    if (!e.target.value) return
    setAction(e.target.value)
  }

  function renderRemoveDeduction() {
    return (
      <RemoveDeduction
        selectedItems={selectedItems}
        onSubmitted={onSubmitted}
      />
    )
  }

  function renderRestoreDeduction() {
    return (
      <RestoreDeduction
        selectedItems={selectedItems}
        onSubmitted={onSubmitted}
      />
    )
  }

  function renderSelectActions() {
    return currentTab === 'contested' ? (
      <S.Select
        data-testid="selectActions"
        label={t('labels.selectTypeOfAction')}
        onChange={selectReason}
        value={action}
        options={options}
      />
    ) : (
      <span />
    )
  }

  return showComponent() ? (
    <S.Wrapper>
      {renderSelectActions()}
      {renderStrategy()}
    </S.Wrapper>
  ) : (
    <span />
  )
}
export { ManagerConciliationActions }
