import { useToaster } from '@creditas/toaster'
import { Context } from '../components/ConfirmConciliation/ConfirmConciliation.style'
import { Typography } from '@creditas/typography'
import React from 'react'
import { ActionLink } from '@creditas/button'
import { useAlert } from './useAlert'

export interface Ask {
  title: string
  text: string
  action: string
}

export interface ConfirmAction {
  ask: Ask
  loading: string
  error: string
  success: string
  confirm: (...args) => Promise<any>
  level?: 'info' | 'success' | 'warning' | 'danger'
  delay?: number
}

const Message = (props: ConfirmAction, alert) => {
  const { ask, loading, error, success, confirm } = props
  const request = () =>
    new Promise((res, rej) => {
      alert({
        title: loading,
        level: 'info',
        delay: -1,
      })
      confirm()
        .then(r => {
          alert({
            title: success,
            level: 'success',
            delay: 2000,
          })
          res(r)
        })
        .catch(e => {
          alert({
            title: error,
            level: 'danger',
            delay: 2000,
          })
          rej(e)
        })
    })
  return (
    <>
      <Context>
        <Typography variant="form">{ask.title}</Typography>
        <Typography variant="support">{ask.text}</Typography>
      </Context>
      <ActionLink
        onClick={request}
        style={{
          padding: 0,
          textAlign: 'center',
        }}
      >
        {ask.action}
      </ActionLink>
    </>
  )
}

function useConfirmAction() {
  const { push } = useToaster()
  const { alert } = useAlert()
  const confirm = (props: ConfirmAction) => {
    const { level = 'info', delay = -1 } = props
    push(Message({ ...props, level, delay }, alert))
  }
  return { confirm }
}

export { useConfirmAction }
