import { styled } from '@creditas/stylitas'

export const FiltersRow = styled.div`
  display: flex;
  flex-basis: 100%;

  & > div {
    flex-basis: 25%;
    & + div {
      margin-left: 10px;
    }
    &:last-child {
      flex-basis: 50%;
    }
  }
`
