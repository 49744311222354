import React, { FC, useState, useEffect } from 'react'

import { useSortBy } from './SortContext'
import { Props, alignMap, Container, directionMap, SortDirection } from '.'

export const SortBy: FC<Props> = ({
  children,
  columnName,
  align = 'center',
  onChangeSort = () => {},
}) => {
  const { sequence, setSequence } = useSortBy()
  const [direction, setDirection] = useState<SortDirection>('')
  const { icon: Icon } = directionMap[direction]

  useEffect(() => {
    if (sequence?.sortBy !== columnName) {
      setDirection('')
    } else {
      setDirection(sequence.direction)
    }
  }, [sequence])

  function handleClick() {
    const { nextDirection } = directionMap[direction]
    const sortBy = nextDirection ? columnName : ''
    setDirection(nextDirection)
    onChangeSort({ sortBy, direction: nextDirection })
    if (setSequence) {
      setSequence({ sortBy, direction: nextDirection })
    }
  }

  return (
    <Container align={alignMap[align]} onClick={handleClick}>
      {children}
      <Icon />
    </Container>
  )
}
