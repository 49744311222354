import React from 'react'
import {
  Box,
  Flex,
  styled,
  Typography,
  ThemeProvider,
  ButtonSecondary,
  standard as theme,
  ButtonPrimaryTextOnly,
} from '@creditas-ui/react'

import Modal from '../NewDesignSystem/Modal'
import { useRestoreConciliationStatus } from '../../services/bff/mutations/restoreConciliationStatus'

// FIXME: this component logs an error. This is a workarround
ButtonPrimaryTextOnly.defaultProps = { loading: '' } as any

const BugFixRippleEffect = styled.div`
  .ripple {
    display: none;
  }
`

interface Props {
  isOpen: boolean
  onClose(confirmed: boolean): void
  id: string
}

export function RestoreConciliationStatusModal({ isOpen, onClose, id }: Props) {
  const { spacingX } = theme
  const [restoreConciliationStatus] = useRestoreConciliationStatus()
  function confirmAndClose() {
    restoreConciliationStatus({ variables: { id } }).then(() => onClose(true))
  }
  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={isOpen}
        onClose={() => onClose(false)}
        body={
          <Box textAlign="left" mx={spacingX['12xl']}>
            <Box mb={spacingX['8xl']}>
              <Typography variant="headingLgLight" color="neutral.90">
                Deseja alterar uma conciliação já aprovada?
              </Typography>
            </Box>
            <Box mb={spacingX['8xl']}>
              <Typography variant="bodyMdRegular" color="neutral.60">
                Ao prosseguir com a alteração, a conciliação voltará para o
                status ’Pendente’ e uma nova aprovação será necessária.
              </Typography>
            </Box>
            <Typography variant="bodyMdRegular">
              Boletos já emitidos precisarão ser atualizados manualmente para
              condizer com as mudanças realizadas.
            </Typography>
          </Box>
        }
        footer={
          <Flex
            width="100%"
            justifyContent="flex-end"
            mx={spacingX['12xl']}
            mb={spacingX['12xl']}
            gridGap={spacingX['8xl']}
          >
            <ButtonSecondary onClick={() => onClose(false)}>
              Cancelar
            </ButtonSecondary>
            <BugFixRippleEffect>
              <ButtonPrimaryTextOnly size="large" onClick={confirmAndClose}>
                Prosseguir com alteração
              </ButtonPrimaryTextOnly>
            </BugFixRippleEffect>
          </Flex>
        }
      />
    </ThemeProvider>
  )
}
