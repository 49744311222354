export function downloadFile(file: Blob | String, filename) {
  const anchor = document.createElement('a')
  anchor.setAttribute('data-testid', 'download-blob')
  anchor.setAttribute('target', 'blank')
  anchor.download = filename
  anchor.href = typeof file === 'string' ? file : URL.createObjectURL(file)
  document.body.appendChild(anchor)
  anchor.click()
  anchor.remove()
}
