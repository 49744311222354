export function getStatus(key: string) {
  return (
    {
      PENDING: 'warning',
      APPROVED: 'success',
      PRODUCT: 'warning',
      DISCHARGING: 'warning',
      REQUESTING: 'success',
      REFINANCING: 'info',
    }[key] || ''
  )
}
