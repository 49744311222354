import React, { ReactNode, useEffect, useState } from 'react'
import { Container, Content, Header, Footer, Body, Close } from './Style'

export interface ModalProps {
  open: boolean
  header?: string | ReactNode
  body?: string | ReactNode
  footer?: string | ReactNode
  closeButton?: ReactNode
  onClose: () => void
}

const Modal = ({
  open,
  onClose,
  header,
  body,
  footer,
  closeButton,
}: ModalProps) => {
  const [openModal, setOpenModal] = useState(open)
  const [debounceModal, setDebounceModal] = useState(open)

  function close() {
    setDebounceModal(false)
    onClose()
    setTimeout(() => {
      setOpenModal(false)
    }, 300)
  }

  useEffect(() => {
    setTimeout(() => {
      setDebounceModal(open)
    }, 300)
  }, [openModal])

  useEffect(() => setOpenModal(open), [open])

  return openModal ? (
    <Container
      className={debounceModal ? 'open' : ''}
      data-testid={'modal-container'}
    >
      <Content className={debounceModal ? 'open' : ''}>
        <Header>
          {closeButton ? (
            closeButton
          ) : (
            <Close onClick={close} data-testid={'modal-close'} />
          )}
          {header}
        </Header>
        <Body>{body}</Body>
        <Footer>{footer}</Footer>
      </Content>
    </Container>
  ) : (
    <span />
  )
}

export default Modal
