import { Card } from '@creditas/card'
import { styled } from '@creditas/stylitas'

export const PageCard = styled(Card)`
  margin: 20px auto;

  &:hover {
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    box-shadow: none;
  }

  margin-top: 40px;
  padding: 32px 35px 32px 24px;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  & > * {
    width: 50%;
  }

  @media (max-width: 1080px) {
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    & > * {
      width: 100%;
    }
  }
`

export const Instructions = styled.div`
  padding-right: 40px;

  @media (max-width: 1080px) {
    padding-right: 0;
    margin-bottom: 40px;
  }

  .title {
    margin-bottom: 32px;
  }
  .subtitle {
    margin-bottom: 16px;
    font-weight: 600;
  }

  ul {
    margin-left: 20px;
    line-height: 3.5;
  }

  ul li p {
    line-height: 38px;
  }

  strong {
    font-weight: 700;
  }
`
