import { gql, useMutation } from '@creditas/apollo'

export const CREATE_BATCH_FILE_MUTATION = gql`
  mutation createConciliationBatchFile($conciliationId: ID!) {
    spreadsheet: createConciliationBatchFile(conciliationId: $conciliationId) {
      url
    }
  }
`

export const UPLOAD_BATCH_FILE_MUTATION = gql`
  mutation uploadConciliationBatchFile($file: Upload!, $conciliationId: ID!) {
    uploadConciliationBatchFile(file: $file, conciliationId: $conciliationId)
  }
`

export const useUploadBatchFile = () => {
  return useMutation<UploadResponse, UploadVariables>(
    UPLOAD_BATCH_FILE_MUTATION,
  )
}

export const useCreateBatchFile = () => {
  return useMutation<BatchFileResponse, BatchFileVariables>(
    CREATE_BATCH_FILE_MUTATION,
  )
}

interface UploadResponse {
  message: string
}

interface UploadVariables {
  file: File
  conciliationId: string
}

interface BatchFileResponse {
  spreadsheet: {
    url: string
  }
}

interface BatchFileVariables {
  conciliationId: string
}
