export * from './Pagination'

export interface PaginationProps {
  limit?: number
  page?: number
  total: number
  onChange?: (event: PaginationEvents) => void
}

export interface PaginationEvents {
  limit: number
  page: number
}

export const PAGINATION_DEFAULT = {
  limit: 10,
  page: 1,
}
