import { styled } from '@creditas/stylitas'
import { InputSelect } from '@creditas/form-elements'

export const Select = styled(InputSelect)`
  margin-bottom: -16px;
  margin-right: 1rem;
  width: 310px;
  height: 50px;

  legend {
    top: 16px;
  }
`
