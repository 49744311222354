import { PAGINATION_DEFAULT } from '../components/Pagination'

const usePagination = useState => {
  const url = new URL(location.href)
  const page =
    parseFloat(url.searchParams.get('page') as string) ||
    PAGINATION_DEFAULT.page
  const limit =
    parseFloat(url.searchParams.get('limit') as string) ||
    PAGINATION_DEFAULT.limit
  return useState({ page, limit })
}

export { usePagination }
