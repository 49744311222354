import React from 'react'
import { ApolloProvider, useCreateApolloUploadClient } from '@creditas/apollo'

import tokens from '@creditas/tokens'
import { Global } from '@creditas/global'
import { ThemeProvider } from '@creditas/stylitas'
import { ToasterProvider } from '@creditas/toaster'

import { Routes } from './Routes'
import { AppCustomProps } from '.'
import { TranslatedErrorBoundary } from './components'
import { ConciliatorLoader, CustomPropsProvider } from './contexts'

export function App(props: AppCustomProps) {
  const apolloClient = useCreateApolloUploadClient({
    authorization: props.authToken,
    uri: props.BFF_URI!,
  })
  return (
    <TranslatedErrorBoundary>
      <CustomPropsProvider value={props}>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={tokens}>
            <ConciliatorLoader>
              <ToasterProvider>
                <Global />
                <Routes />
              </ToasterProvider>
            </ConciliatorLoader>
          </ThemeProvider>
        </ApolloProvider>
      </CustomPropsProvider>
    </TranslatedErrorBoundary>
  )
}
