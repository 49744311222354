export * from './index.spa'

export type AppCustomProps = typeof defaultCustomProps

export const defaultCustomProps = {
  api: 'DEXTER',
  language: 'ptBr',
  productBasePath: '',
  authToken: '',
  userType: '',
  BFF_URI: '',
}
