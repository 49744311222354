import { styled } from '@creditas/stylitas'
import { IconSystemClose } from '@creditas/icons/dist/action'
import { Button as B } from '@creditas/button'
import { Checkbox as C } from '@creditas/checkbox'

export const Checkbox = styled(C)`
  strong {
    font-weight: bold;
  }
`

export const Field = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 4rem;
    height: 4rem;
    fill: #11bb77;
  }
`
export const Input = styled.input`
  height: 64px;
  font-size: 36px;
  font-weight: 300;
  line-height: 64px;
  letter-spacing: 0.5px;
  color: #333333;
  border-radius: 0;
  border: none;
  background: none;
  margin: 1rem 0;
  padding: 0.5rem;
  border-left: 1px solid #11bb77;

  &.invalid {
    border-color: #ff7711;
  }

  &.full-width {
    width: 100%;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c7cec9;
  }
`

export const Container = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  transition: all 150ms ease-in-out;

  &.open {
    background-color: rgba(0, 0, 0, 0.8);
  }
`

export const Content = styled.div`
  position: absolute;
  background-color: white;
  width: 576px;
  height: 100%;
  right: -100%;
  top: 0;
  z-index: 100;
  box-shadow: -8px 0px 32px rgba(31, 45, 39, 0.08);
  display: flex;
  flex-direction: column;
  transition: all 150ms ease-in-out;

  &.open {
    right: 0;
  }
`

export const Close = styled(IconSystemClose)`
  fill: #1f2d27;
  float: right;
  margin-right: -8px;
  margin-top: -8px;
  cursor: pointer;
`

export const Header = styled.div`
  padding: 2.1rem 2.1rem 0 2.1rem;
`

export const Body = styled.div`
  flex-grow: 1;
  padding: 2.1rem;
  overflow: auto;
`

export const Footer = styled.div`
  padding: 0 2.1rem 2.1rem 2.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Button = styled(B)`
  height: 60px;
  background: #49e295;
  border-radius: 16px;
  line-height: 0;
  min-width: inherit;
  color: #1f2d27;

  &:hover:enabled {
    background-color: #0f7c50;
  }

  svg {
    fill: #1f2d27;
    transform: scale(1.5, 1.5);
  }
`

export const ButtonWhite = styled(B)`
  height: 60px;
  color: #49e295;
  border-radius: 16px;
  line-height: 0;
  min-width: inherit;

  &:hover:enabled {
    color: #0f7c50;
  }
`

export const TimelineContent = styled.div`
  border-bottom: 1px solid #edf0ef;
  margin-right: -2.1rem;
  margin-left: -2.1rem;
  padding: 1.625rem 2rem;
  text-align: right;
  position: relative;
`

export const TimelineLine = styled.div`
  border: 1px solid #11bb77;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 150ms ease-in-out;
`

export const TimeLineText = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const TimeLineAction = styled.div`
  cursor: pointer;
  &.active svg {
    fill: #333333;
  }
`
