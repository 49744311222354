import { styled } from '@creditas/stylitas'

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`

export const MessageBox = styled.div`
  display: flex;
  margin: auto;
  min-height: 300px;
  align-items: center;
`
