import { ACTION, LABEL, REASONS, SelectReasonFactory } from '.'
import { AnalyticsActions } from '../../interfaces'

export function selectReasons(action: ACTION): SelectReasonFactory {
  switch (action) {
    case ACTION.REMOVE_REASONS:
      return {
        analytics: AnalyticsActions.RemoveConciliationReason,
        options: [
          REASONS.RETIRED,
          REASONS.VACATION,
          REASONS.TERMINATION,
          REASONS.LIMIT,
          REASONS.OTHERS,
        ],
        label: LABEL.REMOVE,
      }
    case ACTION.CONTEST_REASONS:
      return {
        analytics: AnalyticsActions.ContestConciliationReason,
        options: [
          REASONS.RETIRED,
          REASONS.VACATION,
          REASONS.TERMINATION,
          REASONS.LIMIT,
          REASONS.OTHERS,
        ],
        label: LABEL.CONTEST,
      }
    case ACTION.INCLUDE_REASONS:
      return {
        analytics: AnalyticsActions.RestoreConciliationReason,
        options: [
          REASONS.INCORRECT_EMPLOYEE,
          REASONS.WRONG_TERMINATION,
          REASONS.DEDUCTED,
          REASONS.OTHERS,
        ],
        label: LABEL.INCLUDE,
      }
  }
}
