import React from 'react'
import { HelpCircle } from '@creditas/icons/dist/alert'
import { HelpLinkProps } from './index'
import { Link } from './HelpLink.style'

const HelpLink = ({ anchorId }: HelpLinkProps) => (
  <Link
    target="_blank"
    title="Dúvidas? Veja mais detalhes aqui"
    href={`https://landing.creditas.com.br/self-onboarding-manual#${anchorId}`}
  >
    <HelpCircle />
  </Link>
)

export { HelpLink }
