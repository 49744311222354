import { FC } from 'react'
import { DisabledArrowDown } from './SortBy.style'
import { ArrowUp, ArrowDown } from '@creditas/icons/dist/navigation'

export * from './SortBy'
export * from './SortContext'
export * from './SortBy.style'

export type SortDirection = 'asc' | 'desc' | ''

export type JustifyContent = 'center' | 'flex-start' | 'flex-end'

export interface ContainerProps {
  align: JustifyContent
}

export interface SortDirectionMap {
  nextDirection: SortDirection
  icon: FC
}

export interface SortByEvent {
  sortBy: string
  direction: SortDirection
}

export const directionMap: { [key: string]: SortDirectionMap } = {
  '': {
    nextDirection: 'asc',
    icon: DisabledArrowDown,
  },
  asc: {
    nextDirection: 'desc',
    icon: ArrowUp,
  },
  desc: {
    nextDirection: '',
    icon: ArrowDown,
  },
}

export const alignMap: { [key: string]: JustifyContent } = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
}

export interface Props {
  columnName: string
  align?: 'left' | 'right' | 'center'
  onChangeSort?(event: SortByEvent): void
}
