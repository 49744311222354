import { TableHead as _TableHead } from '@creditas/table'
import { styled } from '@creditas/stylitas'

export const TableHead = styled(_TableHead)`
  font-weight: bold;
`

export const CardHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
`
