import { useTranslation } from '@creditas/i18n'
import React, { useContext } from 'react'
import { emitEvent } from '../../helpers'
import { AnalyticsActions, isAdministrator } from '../../interfaces'
import { ConciliatorPropsContext, CustomPropsContext } from '../../contexts'
import { Button } from '../Form'
import { ChangeConciliationProps } from './index'

export const ChangeConciliation = (props: ChangeConciliationProps) => {
  const { id, onChange, disabled, editing } = props
  const { role } = useContext(ConciliatorPropsContext) || { role: '' }
  const customProps = useContext(CustomPropsContext)
  const { t } = useTranslation()

  function event(action: string) {
    emitEvent(customProps, {
      value: id,
      action,
    })
  }

  function change() {
    if (disabled) return

    const changedEditValue = !editing

    !changedEditValue
      ? event(AnalyticsActions.InitEditMode)
      : event(AnalyticsActions.ExitEditMode)
    onChange(changedEditValue)
  }

  function showComponent() {
    return isAdministrator(role)
  }

  function label() {
    return isAdministrator(role)
      ? 'labels.changeConciliation'
      : 'labels.contestConciliation'
  }

  return showComponent() ? (
    <Button
      data-testid="change-conciliation"
      onClick={change}
      variant="tertiary"
      disabled={disabled}
    >
      {editing ? t('labels.leaveEditMode') : t(label())}
    </Button>
  ) : (
    <span />
  )
}
