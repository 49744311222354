import { css, styled, utilities } from '@creditas/stylitas'

const backgroundColor = ({ theme }) => css`
  background-color: ${utilities.palette.color(theme, 'neutral01')};
`

export const Header = styled.header`
  padding: 20px 0;
  ${backgroundColor}
  margin-bottom: 20px;

  & > div {
    display: flex;
    align-items: center;
    button {
      min-width: 48px;
      padding: 8px;
      height: 48px;
      margin: auto;
      margin-right: 10px;
      & + div {
        flex: 1;
      }
    }
  }
`
