import React, { useEffect, useState } from 'react'
import { useTranslation } from '@creditas/i18n'
import { Table, TableBody } from '@creditas/table'
import { Checkbox } from '@creditas/checkbox'
import { TableHead } from '..'
import { PayrollDiscountTableProps } from '.'
import { formatCurrency, formatCPF, formatDate, getStatus } from '../../helpers'
import {
  TableCell,
  TableRow,
  TableCellCompact,
  TableCellStatus,
  Info,
} from './PayrollDiscountTable.style'
import { Tag, Variants } from '@creditas/tag'
import { PayrollDeduction } from '../../services/bff/types'

export const PayrollDiscountTable = (props: PayrollDiscountTableProps) => {
  const { payrollDiscounts = [], onSelected, reset, tab } = props

  const { t } = useTranslation()
  const [all, setAll] = useState(false)
  const [itemSelected, setItemSelected] = useState({})
  const locale = t('locale')
  const isContestedTab = props.tab === 'contested'

  useEffect(() => {
    setItemSelected({})
    setAll(false)
  }, [reset, tab])

  function selectAll() {
    setAll(!all)
    const item = { ...itemSelected }
    payrollDiscounts.forEach(installment => {
      const { id } = installment
      if (shouldDisableCheckbox(installment)) {
        item[id] = false
      } else {
        item[id] = !all
      }
    })
    setItemSelected(item)
    handleSelected(item)
  }

  function selectOne(event) {
    const item = { ...itemSelected }
    item[event.target.value] = !item[event.target.value]
    setItemSelected(item)
    handleSelected(item)
  }

  function handleSelected(item: any) {
    const list: string[] = []
    Object.keys(item).forEach(k => {
      if (item[k]) {
        list.push(k)
      }
    })
    onSelected(list)
  }

  function isInstallmentPJ(installment: PayrollDeduction) {
    if (!installment.splitPayrollDeduction) return

    const { companyPartialDiscountAmount } = installment.splitPayrollDeduction
    const { installmentAmount } = installment

    return installmentAmount === companyPartialDiscountAmount
  }

  function shouldDisableCheckbox(installment: PayrollDeduction) {
    if (isInstallmentPJ(installment)) return false
    return !!installment.splitPayrollDeduction && props.tab === 'refused'
  }

  return (
    <Table fullWidth data-testid="discount-table">
      <TableHead>
        <TableRow>
          {props.enabledEditionMode && (
            <TableCell>
              <Checkbox
                id={'all'}
                name={'all'}
                role="checkbox"
                aria-label={'all'}
                aria-checked={all}
                checked={all}
                onChange={selectAll}
              >
                <span />
              </Checkbox>
            </TableCell>
          )}
          <TableCell textAlign="left">
            {t('labels.payrollDiscount.name')}
          </TableCell>
          <TableCell>{t('labels.payrollDiscount.document')}</TableCell>
          <TableCell>{t('labels.payrollDiscount.companyDocument')}</TableCell>
          {!props.enabledReason && (
            <TableCell>{t('labels.payrollDiscount.requestDate')}</TableCell>
          )}
          <TableCell>{t('labels.payrollDiscount.installment')}</TableCell>
          {isContestedTab ? (
            <TableCell>{t('labels.payrollDiscount.payrollDiscount')}</TableCell>
          ) : (
            <TableCell>
              {t('labels.payrollDiscount.installmentValue')}
            </TableCell>
          )}
          {isContestedTab && (
            <TableCell>{t('labels.payrollDiscount.employeeCharge')}</TableCell>
          )}
          {!props.enabledReason && (
            <TableCell>{t('labels.totalValue')}</TableCell>
          )}

          <TableCell>{t('labels.payrollDiscount.type')}</TableCell>
          {props.enabledReason && (
            <TableCell>{t('labels.payrollDiscount.reason')}</TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody variant="striped">
        {payrollDiscounts.map(installment => (
          <TableRow
            className={itemSelected[installment.id] ? 'checked' : ''}
            key={installment.id}
            data-testid={installment.id}
          >
            {props.enabledEditionMode && (
              <TableCell>
                <Checkbox
                  id={installment.id}
                  name={'select'}
                  value={installment.id}
                  checked={itemSelected[installment.id] || false}
                  onChange={selectOne}
                  role="checkbox"
                  aria-label={'select'}
                  aria-checked={itemSelected[installment.id] || false}
                  disabled={shouldDisableCheckbox(installment)}
                >
                  <span />
                </Checkbox>
              </TableCell>
            )}
            <TableCell>{installment.clientName}</TableCell>
            <TableCell textAlign="center" style={{ minWidth: '150px' }}>
              {formatCPF(installment.clientDocument)}
            </TableCell>
            <TableCell textAlign="center" style={{ minWidth: '150px' }}>
              {formatCPF(installment.companyDocument)}
            </TableCell>
            {!props.enabledReason && (
              <TableCell textAlign="center">
                {formatDate(locale, installment.requestDate)}
              </TableCell>
            )}
            <TableCell textAlign="center">
              {installment.installmentNumber}/{installment.numberOfInstallments}
            </TableCell>
            <TableCell textAlign="center">
              {formatCurrency(
                locale,
                isContestedTab
                  ? installment.splitPayrollDeduction
                    ? installment.splitPayrollDeduction
                        .companyPartialDiscountAmount
                    : 0
                  : installment.installmentAmount,
              )}
            </TableCell>
            {isContestedTab && (
              <TableCell textAlign="center">
                {formatCurrency(
                  locale,
                  installment.splitPayrollDeduction
                    ? installment.splitPayrollDeduction.totalInstallmentAmount -
                        installment.splitPayrollDeduction
                          .companyPartialDiscountAmount
                    : installment.installmentAmount,
                )}
              </TableCell>
            )}
            {!props.enabledReason && (
              <TableCell textAlign="center">
                {formatCurrency(locale, installment.loanAmount)}
              </TableCell>
            )}
            <TableCellStatus textAlign="center">
              <Tag
                label={t(`contractType.${installment.contractType}`, {
                  defaultValue: installment.contractType,
                })}
                variant={getStatus(installment.contractType) as Variants}
              />
            </TableCellStatus>
            {props.enabledReason && (
              <TableCellCompact textAlign="left" title={installment.reason}>
                <Info /> {installment.reason}
              </TableCellCompact>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
