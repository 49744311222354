import { gql, useMutation } from '@creditas/apollo'

export const RESTORE_DEDUCTION = gql`
  mutation RestorePayrollDeductions(
    $restorePayrollDeductions: [RestorePayrollDeduction]!
  ) {
    restorePayrollDeductions(
      restorePayrollDeductions: $restorePayrollDeductions
    ) {
      id
    }
  }
`

const useRestoreDeduction = () => {
  return useMutation(RESTORE_DEDUCTION)
}

export { useRestoreDeduction }
