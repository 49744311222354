import React, { useContext, useEffect, useState } from 'react'
import Modal from '../NewDesignSystem/Modal'
import { Typography } from '@creditas/typography'
import { Navigation } from '@creditas/icons'
import { Button, ButtonWhite, Close, Checkbox } from '../NewDesignSystem'
import Timeline from '../NewDesignSystem/Timeline'
import { useTranslation } from '@creditas/i18n'
import { PayrollDeduction } from '../../services/bff/types'
import { terminationForm } from './TerminationForm'
import { Termination, useTermination } from '../../services/bff/mutations'
import { ConciliatorPropsContext, CustomPropsContext } from '../../contexts'
import { DatePicker } from '../NewDesignSystem/Datepicker'
import CurrencyInput from '../NewDesignSystem/CurrencyInput'
import TerminationMessage from './TerminationMessage'
import { emitEvent } from '../../helpers'
import { AnalyticsActions } from '../../interfaces'

export interface TerminationModalProps {
  deductions: PayrollDeduction[]
  open: boolean
  onClose: (submitted: string[]) => void
  conciliationDate: string | undefined
}

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const ArrowRight = Navigation.ArrowRight

const TerminationModal = ({
  open,
  onClose,
  deductions = [],
  conciliationDate,
}: TerminationModalProps) => {
  const { t } = useTranslation()
  const { email, name } = useContext(ConciliatorPropsContext) || {
    email: '',
    name: '',
  }
  const customProps = useContext(CustomPropsContext)
  const [canceling, setCanceling] = useState(false)
  const [step, setStep] = useState(1)
  const [terminations, setTerminations] = useState<Termination[]>([])
  const [termination, {}] = useTermination()
  const [
    form,
    setForm,
    isValid,
    handleInputChange,
    formValue,
    formReset,
  ] = terminationForm()

  const event = (action: string, value: string = '') =>
    emitEvent(customProps, { value, action })

  const handleCheckbox = () => {
    setForm({ shouldPayrollDiscountFlag: !form.shouldPayrollDiscountFlag })
  }

  function dateChange(date: string) {
    setForm({ terminationAt: date })
  }

  function onStepChange(step: number) {
    if (step > terminations.length) {
      handlerClose()
    } else {
      setStep(step)
      setForm(terminations[step - 1])
    }
  }

  function parseDeductionToTermination(
    form: PayrollDeduction,
  ): Partial<Termination> {
    return form
      ? {
          id: form.id,
          conciliationDate,
          conciliatorEmail: email,
          conciliatorName: name,
          clientDocumentValue: form.clientDocument,
          clientName: toTitleCase(form.clientName),
          companyDocumentValue: form.companyDocument,
          loanType: form.contractType,
        }
      : {}
  }

  async function submit() {
    if (form.submitted) {
      onStepChange(step + 1)
    } else {
      if (isValid) {
        await termination({
          variables: { termination: formValue() },
        })
        const value = form.shouldPayrollDiscountFlag ? 'true' : 'false'
        event(AnalyticsActions.TerminationShouldPayrollDiscount, value)
        setForm({ submitted: true })
        terminations[step - 1].submitted = true
      }
    }
  }

  function reset() {
    setCanceling(false)
    formReset()
  }

  function close() {
    if (terminations.filter(t => t.submitted).length === terminations.length) {
      handlerClose()
    } else {
      event(AnalyticsActions.TerminationRequestCloseModal)
      setCanceling(true)
    }
  }

  function confirmClose(confirmed: boolean) {
    if (confirmed) {
      event(AnalyticsActions.TerminationConfirmCloseModal)
      handlerClose()
    } else {
      event(AnalyticsActions.TerminationCancelCloseModal)
      setCanceling(false)
    }
  }

  function handlerClose() {
    onClose(terminations.filter(t => t.submitted).map(t => t.id))
  }

  useEffect(() => {
    if (open) {
      const data = deductions.map(deduction => ({
        ...form,
        ...parseDeductionToTermination(deduction),
      }))
      setTerminations(data)
      setForm(data[0])
    } else {
      reset()
    }
  }, [open])

  function next() {
    onStepChange(step + 1)
  }

  function month(): string {
    const locale = t('locale')
    const date = new Date(conciliationDate || '')
    const dateFormat = { month: 'long' }
    return date.toLocaleDateString(locale, dateFormat)
  }

  return (
    <Modal
      open={open}
      onClose={handlerClose}
      closeButton={<Close onClick={close} data-testid={'modal-close'} />}
      header={
        <>
          <Typography variant="h4">{t('termination.title')}</Typography>
          <Typography variant="h6">
            {t('termination.subtitle')}: {form?.clientName}
          </Typography>
          <Timeline
            data-testid={'timeline'}
            steps={deductions.length}
            current={step}
            disabled={!form.submitted}
            placeholder={t('termination.of')}
            onChange={onStepChange}
          />
        </>
      }
      body={
        canceling || form.submitted ? (
          <TerminationMessage
            data={form}
            type={canceling ? 'warning' : 'success'}
            confirmClose={confirmClose}
          />
        ) : (
          <>
            <Typography variant="paragraph">
              {t('termination.description')}
            </Typography>
            <CurrencyInput
              data-testid={'terminationAmount'}
              name={'terminationAmount'}
              value={form.terminationAmount}
              onChange={handleInputChange}
              placeholder={t('termination.value')}
            />
            <DatePicker
              data-testid={'terminationAt'}
              placeholder={t('termination.date')}
              value={form.terminationAt}
              onChange={dateChange}
            />
          </>
        )
      }
      footer={
        <>
          {form.submitted && deductions.length > 1 ? (
            <ButtonWhite variant={'tertiary'} onClick={close}>
              {t('termination.cancel')}
            </ButtonWhite>
          ) : (
            <span />
          )}
          {!form.submitted && !canceling && (
            <Checkbox
              id={'all'}
              name={'shouldPayrollDiscountFlag'}
              checked={form.shouldPayrollDiscountFlag}
              onChange={handleCheckbox}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t(`termination.checkbox`, {
                    month: month(),
                  }),
                }}
              ></span>
            </Checkbox>
          )}
          {!canceling && !form.submitted && (
            <Button
              children=" "
              disabled={!isValid}
              type={'submit'}
              onClick={submit}
              data-testid={'termination-submit'}
              endIcon={<ArrowRight />}
            />
          )}
          {!canceling && form.submitted && (
            <Button
              children=" "
              type={'button'}
              onClick={next}
              data-testid={'termination-next'}
              endIcon={<ArrowRight />}
            />
          )}
        </>
      }
    />
  )
}

export default TerminationModal
