import React, { useState } from 'react'

import { PaginationTable } from '@creditas/pagination'
import { PAGINATION_DEFAULT, PaginationProps } from './index'
import { PaginationContainer } from './Pagination.style'
import { useTranslation } from '@creditas/i18n'

export const Pagination = ({
  page = PAGINATION_DEFAULT.page,
  limit = PAGINATION_DEFAULT.limit,
  total = 0,
  onChange = () => null,
}: Partial<PaginationProps>) => {
  const { t } = useTranslation()

  function onChangePage(page) {
    onChange({ page, limit })
  }

  function onChangePageSize(limit) {
    onChange({ page, limit })
  }

  function calcPage(page) {
    return page < 1 ? 1 : page
  }

  return (
    <PaginationContainer data-testid="pagination-container">
      <PaginationTable
        current={calcPage(page)}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        pageSize={limit}
        total={total}
        translations={{
          overview: (current, total) => `${current} ${t('labels.of')} ${total}`,
          select: option => `${t('labels.show')} ${option}`,
        }}
      />
    </PaginationContainer>
  )
}
