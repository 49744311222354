import { styled } from '@creditas/stylitas'
import { ActionLink as _ActionLink } from '@creditas/button'
import { Typography as _Typography } from '@creditas/typography'

export const ActionLink = styled(_ActionLink)`
  padding: 0;
  &:focus {
    border: 0;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-right: 2rem;
`

export const Typography = styled(_Typography)`
  b {
    font-weight: bold;
  }
`
