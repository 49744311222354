import React from 'react'
import { Navigation } from '@creditas/icons'

type Props = {
  isExpanded: boolean
}

const AccordionIcon = ({ isExpanded }: Props) => {
  const UpIcon = Navigation.ChevronUp
  const DownIcon = Navigation.ChevronDown

  return isExpanded ? (
    <UpIcon data-testid="retract" />
  ) : (
    <DownIcon data-testid="expand" />
  )
}

export default AccordionIcon
