import React from 'react'
import { Typography } from '@creditas/typography'
import { useTranslation } from '@creditas/i18n'
import { styled } from '@creditas/stylitas'
import IconSuccess from '../NewDesignSystem/IconSuccess'
import IconWarning from '../NewDesignSystem/IconWarning'
import { Button, ButtonWhite } from '../NewDesignSystem'
import { Termination } from '../../services/bff/mutations'

export interface TerminationMessageProps {
  data: Termination
  type: 'success' | 'warning'
  confirmClose: (confirmed: boolean) => void
}

const ContainerMessage = styled.div`
  padding: 1rem;
  display: flex;
  flex-flow: column;
  align-items: center;

  > p {
    text-align: center;
    margin-top: 1rem;

    strong {
      font-weight: bold;
    }
  }

  > div {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 1rem;
  }
`

const TerminationMessage = ({
  data,
  type,
  confirmClose,
}: TerminationMessageProps) => {
  const { t } = useTranslation()

  return (
    <ContainerMessage>
      {type === 'success' ? <IconSuccess /> : <IconWarning />}
      <Typography variant="h4">{t(`termination.${type}.title`)}</Typography>
      <Typography
        variant="paragraph"
        dangerouslySetInnerHTML={{
          __html: t(`termination.${type}.description`, {
            name: data.clientName,
          }),
        }}
      />
      <Typography variant="paragraph">
        {t(`termination.${type}.confirm`)}
      </Typography>
      {type === 'warning' && (
        <div>
          <Button onClick={() => confirmClose(true)}>
            {t('termination.confirm')}
          </Button>
          <ButtonWhite variant={'tertiary'} onClick={() => confirmClose(false)}>
            {t('termination.cancel')}
          </ButtonWhite>
        </div>
      )}
    </ContainerMessage>
  )
}

export default TerminationMessage
