import { useTranslation } from '@creditas/i18n'
import React, { useContext, useState } from 'react'
import { Typography } from '@creditas/typography'
import { CustomPropsContext } from '../../contexts'
import { emitEvent } from '../../helpers'
import { AnalyticsActions } from '../../interfaces'
import { useConfirmAction } from '../../hooks'
import { useRemoveDeduction } from '../../services/bff/mutations'
import { SelectReason, ACTION, selectReasons } from '../SelectReason'
import { Actions, ButtonCompact } from '../Form'
import { RemoveDeductionProps } from '.'

export const RemoveDeduction = (props: RemoveDeductionProps) => {
  let { selectedItems = [], onSubmitted } = props
  const customProps = useContext(CustomPropsContext)
  const { t } = useTranslation()
  const [reason, setReason] = useState('')
  const { confirm } = useConfirmAction()
  const [submitted, setSubmitted] = useState(false)
  const [removeDeduction, { loading }] = useRemoveDeduction()

  const event = (action: string) =>
    emitEvent(customProps, {
      value: reason,
      action,
    })

  function changeReason(reason: string) {
    setReason(reason)
  }

  const success = async () => {
    return new Promise((res, rej) => {
      const removePayrollDeductions = selectedItems.map(id => ({
        id,
        reason,
      }))
      event(AnalyticsActions.RemoveConciliationReasonRequested)
      removeDeduction({
        variables: { removePayrollDeductions },
      })
        .then(r => {
          res(r)
          reset()
          event(AnalyticsActions.RemoveConciliationReasonCompleted)
          onSubmitted()
        })
        .catch(e => {
          rej(e)
          reset()
        })
    })
  }

  const askConfirmation = async () => {
    setSubmitted(true)
    if (isFormValid()) {
      confirm({
        ask: {
          title: t('alerts.removeDeduction.title'),
          text: t('alerts.removeDeduction.text'),
          action: t('alerts.removeDeduction.action'),
        },
        success: t('alerts.removeDeduction.success'),
        error: t('alerts.removeDeduction.error'),
        loading: t('alerts.removeDeduction.loading'),
        confirm: success,
      })
    } else {
      event(AnalyticsActions.RemoveConciliationSubmitInvalid)
    }
  }

  function reset() {
    setReason('')
    setSubmitted(false)
    selectedItems = []
  }

  function isFormValid() {
    return reason && selectedItems.length > 0
  }

  function inputInvalid(): boolean {
    return submitted && !reason
  }

  function itemsNotSelected(): boolean {
    return submitted && !selectedItems.length
  }

  return (
    <Actions>
      <SelectReason
        value={reason}
        {...selectReasons(ACTION.REMOVE_REASONS)}
        invalid={inputInvalid()}
        onChange={changeReason}
      />
      <ButtonCompact
        onClick={askConfirmation}
        isLoading={loading}
        disabled={loading}
      >
        {t('labels.removeFromConciliation', { length: selectedItems.length })}
      </ButtonCompact>
      {itemsNotSelected() && (
        <Typography color="danger">
          {t('labels.deductionNotSelected')}
        </Typography>
      )}
    </Actions>
  )
}
