import { styled, utilities } from '@creditas/stylitas'

import { Container as BaseContainer } from '@creditas/layout'

const Container = styled(BaseContainer)`
  padding: 40px;
  height: 650px;
  max-width: 480px;

  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => utilities.radius.small({ theme })};

  .w100p {
    width: 100%;
  }

  div {
    &:first-of-type label {
      margin: auto;
    }

    p + p,
    button + button {
      margin-top: 10px;
    }
  }

  select {
    width: 100%;
  }
`

export default Container
