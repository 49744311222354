import { useTranslation } from '@creditas/i18n'
import React, { useContext } from 'react'
import { Button } from '@creditas/button'
import { ConfirmConciliationProps, isConfirmationDisabled } from './index'
import { useConfirmConciliation } from '../../services/bff/mutations'
import { useParams } from 'react-router-dom'
import { emitEvent } from '../../helpers'
import { AnalyticsActions } from '../../interfaces'
import { CustomPropsContext } from '../../contexts'
import { useConfirmAction } from '../../hooks'
import { CONCILIATION_STATUS } from '../../services/bff/types'

export const ConfirmConciliation = ({
  status,
  editing,
  onConfirm,
}: ConfirmConciliationProps) => {
  const customProps = useContext(CustomPropsContext)
  const { t } = useTranslation()
  const { confirm: alertConfirm } = useConfirmAction()
  const { id } = useParams<{ id: string }>()
  const [confirm, { loading }] = useConfirmConciliation(id)

  const event = (action: string) =>
    emitEvent(customProps, {
      value: id,
      action,
    })

  const success = async () => {
    return new Promise((res, rej) => {
      event(AnalyticsActions.ConfirmConciliationRequested)
      confirm()
        .then(r => {
          res(r)
          event(AnalyticsActions.ConfirmConciliationCompleted)
          onConfirm()
        })
        .catch(rej)
    })
  }

  const askConfirmation = () => {
    event(AnalyticsActions.ConfirmConciliationAsk)
    alertConfirm({
      ask: {
        title: t('alerts.conciliation.title'),
        text: t('alerts.conciliation.text'),
        action: t('alerts.conciliation.action'),
      },
      success: t('alerts.successConciliation.text'),
      error: t('alerts.errorConciliation.title'),
      loading: t('alerts.loadingConciliation.text'),
      confirm: success,
    })
  }

  function label() {
    if (!status) {
      return 'labels.loadingInstallments'
    } else if (status === CONCILIATION_STATUS.APPROVED) {
      return 'labels.confirmed'
    } else if (status === CONCILIATION_STATUS.CONTESTED) {
      return 'labels.pendingAnalise'
    } else {
      return 'labels.confirmConciliation'
    }
  }

  return (
    <Button
      data-testid={'confirm-conciliation'}
      onClick={askConfirmation}
      isLoading={loading}
      disabled={isConfirmationDisabled(status!) || editing || loading}
    >
      {t(label())}
    </Button>
  )
}
