import { start, registerApplication, pathToActiveWhen } from 'single-spa'
import 'babel-polyfill'

import { envVars } from './envVars'
import { defaultCustomProps } from '.'
import { TOKENS } from '../cypress/support/tokens'

function getLifeCycles(path: string) {
  return () => window.System.import(path)
}

registerApplication({
  name: envVars.appName,
  app: getLifeCycles(`/conciliation/${envVars.appName}.js`),
  activeWhen: pathToActiveWhen('/preview'),
  customProps: {
    ...defaultCustomProps,
    productBasePath: '/preview',
    userType: 'User type Test',
    authToken: sessionStorage.authToken ?? TOKENS.company,
    BFF_URI: envVars.BFF_URI,
  },
})

if (!window.location.pathname || window.location.pathname === '/') {
  window.singleSpaNavigate('/preview')
}

start()
