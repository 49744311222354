import { gql, useQuery } from '@creditas/apollo'

import { GetConciliationParams, GetConciliationsResponse } from '../types'

export const CONCILIATIONS = gql`
  query getConciliations(
    $year: String
    $month: String
    $page: Int
    $limit: Int
    $groupName: String
    $asc: Boolean
    $orderBy: String
    $status: String
  ) {
    conciliations(
      year: $year
      month: $month
      page: $page
      limit: $limit
      groupName: $groupName
      asc: $asc
      orderBy: $orderBy
      status: $status
    ) {
      data {
        id
        totalAmount
        numberOfInstallments
        status
        conciliationGroup {
          name
        }
      }
      total
      limit
      page
    }
  }
`

export const useGetConciliations = (params: GetConciliationParams) => {
  return useQuery<GetConciliationsResponse>(CONCILIATIONS, {
    variables: params,
  })
}
