import React from 'react'
import { useGetConciliator } from '../services/bff/queries'
import { MessageBox } from '../pages/ListGroups/ListGroups.style'
import { Spinner } from '@creditas/spinner/dist'
import { styled } from '@creditas/stylitas'

export interface ConciliatorProps {
  id: string
  email: string
  name: string
  role: string
}

const defaultConciliatorProps = {
  id: '',
  email: '',
  name: '',
  role: '',
}

export const ConciliatorPropsContext = React.createContext<
  ConciliatorProps | undefined
>(defaultConciliatorProps)

export const ConciliatorPropsProvider = ConciliatorPropsContext.Provider

const Box = styled(MessageBox)`
  width: 100%;
  justify-content: center;
`

export const ConciliatorLoader = ({ children }: any) => {
  const { data } = useGetConciliator()
  return (
    <ConciliatorPropsProvider value={data?.conciliator}>
      {data?.conciliator ? (
        children
      ) : (
        <Box>
          <Spinner />
        </Box>
      )}
    </ConciliatorPropsProvider>
  )
}
