import { useTranslation } from '@creditas/i18n'
import React, { useContext, useEffect, useState } from 'react'
import { ContestDeductionProps } from './index'
import { ConciliatorPropsContext, CustomPropsContext } from '../../contexts'
import { emitEvent, pushEvent } from '../../helpers'
import { AnalyticsActions, isCompany } from '../../interfaces'
import { useConfirmAction } from '../../hooks'
import { useContestDeduction } from '../../services/bff/mutations'
import { Typography } from '@creditas/typography'
import { Actions, ButtonCompact } from '../Form'
import { SelectReason, ACTION, selectReasons } from '../SelectReason'
import TerminationModal from '../Termination/TerminationModal'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@creditas/button'
import { envVars } from '../../envVars'

declare global {
  interface Window {
    hj: any
  }
}

const hj = (window.hj =
  window.hj ||
  function() {
    ;(hj.q = hj.q || []).push(arguments)
  })

export const ContestDeduction = (props: ContestDeductionProps) => {
  const { t } = useTranslation()
  const customProps = useContext(CustomPropsContext)
  let { selectedItems = [], enabledEditionMode = false, onSubmitted } = props
  const [reason, setReason] = useState('')
  const { confirm } = useConfirmAction()
  const [contestDeduction, { data, loading }] = useContestDeduction()
  const [submitted, setSubmitted] = useState(false)
  const { role } = useContext(ConciliatorPropsContext) || { role: '' }
  const [modal, setModal] = useState(false)

  const [showBatchFileButton, setShowBatchFileButton] = useState(false)

  const history = useHistory()

  const { id: groupId } = useParams<{ id: string }>()

  const { productBasePath } = useContext(CustomPropsContext)

  const event = (action: string) =>
    emitEvent(customProps, {
      value: reason,
      action,
    })

  function changeReason(reason) {
    setReason(reason)
  }

  const success = async (ids?: string[]) => {
    return new Promise((res, rej) => {
      const contestPayrollDeductions = (ids || selectedItems).map(id => ({
        id,
        reason,
      }))
      event(AnalyticsActions.ContestConciliationReasonRequested)
      contestDeduction({
        variables: { contestPayrollDeductions },
      })
        .then(r => {
          res(r)
          reset()
          event(AnalyticsActions.ContestConciliationReasonCompleted)
          onSubmitted()
        })
        .catch(e => {
          rej(e)
          reset()
        })
    })
  }

  function reasonIsTermination(reason) {
    return reason === t('reasonsChangeConciliation.termination')
  }

  async function handlerModalClose(ids: string[]) {
    setModal(false)
    if (ids.length) {
      await success(ids)
    }
  }

  const askConfirmation = async () => {
    setSubmitted(true)
    if (isFormValid()) {
      if (reasonIsTermination(reason)) {
        setModal(true)
        hj('trigger', 'conciliation_termination')
      } else {
        confirm({
          ask: {
            title: t('alerts.removeDeduction.title'),
            text: t('alerts.removeDeduction.text'),
            action: t('alerts.removeDeduction.action'),
          },
          success: t('alerts.contestDeduction.success'),
          error: t('alerts.contestDeduction.error'),
          loading: t('alerts.contestDeduction.loading'),
          confirm: success,
        })
      }
    } else {
      event(AnalyticsActions.ContestConciliationSubmitInvalid)
    }
  }

  function reset() {
    setReason('')
    setSubmitted(false)
    selectedItems = []
  }

  function isFormValid() {
    return reason !== '' && selectedItems.length > 0
  }

  function inputInvalid(): boolean {
    return submitted && !reason
  }

  function itemsNotSelected(): boolean {
    return submitted && !selectedItems.length
  }

  function showComponent() {
    return isCompany(role) && enabledEditionMode
  }

  useEffect(() => {
    setShowBatchFileButton(envVars.conciliationBatchUpload)
  }, [])

  return showComponent() ? (
    <Actions>
      <SelectReason
        value={reason}
        {...selectReasons(ACTION.CONTEST_REASONS)}
        invalid={inputInvalid()}
        onChange={changeReason}
      />
      <ButtonCompact
        onClick={askConfirmation}
        isLoading={loading}
        disabled={loading}
      >
        {t('labels.contestConciliationAction', {
          length: selectedItems.length,
        })}
      </ButtonCompact>
      {itemsNotSelected() && (
        <Typography color="danger">
          {t('labels.deductionNotSelected')}
        </Typography>
      )}
      {showBatchFileButton && (
        <Button
          variant="secondary"
          onClick={() => {
            history.push({
              pathname: `${productBasePath}/upload/${groupId}`,
              search: location.search,
            })
            pushEvent(customProps, {
              action: 'batch-deductions | show-button',
              value: '1',
            })
          }}
        >
          Solicitar alteração em lote
        </Button>
      )}
      <TerminationModal
        conciliationDate={props.conciliationDate}
        open={modal}
        onClose={handlerModalClose}
        deductions={props.selectedDeductions}
      />
    </Actions>
  ) : (
    <span />
  )
}
