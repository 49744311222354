import React, { forwardRef } from 'react'
import DatepickerBase from 'react-datepicker'
import { DatePickerContainer } from './DatePickerStyle'
import InputField from './InputField'
import { Notification } from '@creditas/icons'
import MaskedInput from 'react-text-mask'

export const Calendar = Notification.Calendar

const InputRef = forwardRef(
  (
    { value, placeholder, onChange, onClick, 'data-testid': testId }: any,
    ref,
  ) => {
    return (
      <MaskedInput
        ref={ref}
        value={value}
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        placeholder={placeholder}
        onChange={onChange}
        render={(ref, props) => (
          <InputField
            {...props}
            data-testid={testId}
            forwardedRef={ref}
            endIcon={
              <Calendar onClick={onClick} data-testid="datepickerButton" />
            }
          />
        )}
      />
    )
  },
)

export interface DatePickerProps {
  'data-testid'?: string
  placeholder: string
  value?: any
  onChange?: any
}

export const DatePicker = ({
  value,
  onChange,
  'data-testid': testId,
  placeholder,
}: DatePickerProps) => {
  return (
    <DatePickerContainer>
      <DatepickerBase
        locale={'ptBr'}
        selected={value}
        dateFormat={'dd/MM/yyyy'}
        placeholderText={placeholder}
        customInput={<InputRef data-testid={testId} />}
        onChange={onChange}
      />
    </DatePickerContainer>
  )
}
