import React, { useState, useCallback, useEffect, useContext } from 'react'

import { Button } from '@creditas/button'
import { useTranslation } from '@creditas/i18n'
import { Typography } from '@creditas/typography'
import { useToaster } from '@creditas/toaster'

import FilesDropZone from '../FilesDropZone/FilesDropZone'
import Container from './UploadFileSelector.style'
import {
  useCreateBatchFile,
  useUploadBatchFile,
} from '../../services/bff/mutations/batchFile'
import { CustomPropsContext } from '../../contexts'
import { emitPageView, pushEvent } from '../../helpers'
import { downloadFile } from '../../helpers/downloadFile'
import { useParams, useHistory } from 'react-router-dom'

const UploadFileSelector = ({ onError }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const toaster = useToaster()
  const [file, setFile] = useState<File>()
  const customProps = useContext(CustomPropsContext)

  const { id } = useParams<{ id: string }>()

  const [createBatchFile, createBatchFileResult] = useCreateBatchFile()
  const [uploadBatchFile, uploadBatchFileResult] = useUploadBatchFile()

  const loading = createBatchFileResult.loading || uploadBatchFileResult.loading

  const handleFileChange = useCallback((files: FileList) => {
    setFile(files[0])
  }, [])

  const acceptedFiles = '.xls, .xlsx'

  function downloadXlsx() {
    pushEvent(customProps, {
      action: 'batch-deductions | download-conciliation',
      value: '1',
    })
    createBatchFile({ variables: { conciliationId: id } })
      .then(({ data }) => {
        downloadFile(data!.spreadsheet.url, 'planilha.xlsx')
      })
      .catch(onError)
  }

  function sendFile() {
    if (loading || !file) return
    pushEvent(customProps, {
      action: 'batch-deductions | upload-file',
      value: '1',
    })
    uploadBatchFile({ variables: { file, conciliationId: id } })
      .then(() => {
        toaster.push('Seu arquivo foi enviado com sucesso')
        history.goBack()
      })
      .catch(onError)
  }

  useEffect(() => {
    emitPageView(customProps)
  }, [])

  return (
    <Container>
      <div className="w100p">
        <FilesDropZone
          accept={acceptedFiles}
          onChangeFiles={handleFileChange}
        />
        <Typography variant="support">{file?.name}</Typography>
      </div>
      <div className="w100p">
        <Typography variant="h6">{t('upload.dropFilesHere')}</Typography>
        <Typography variant="support">
          {t('upload.acceptedFormats')} {acceptedFiles}
        </Typography>
      </div>
      <div className="w100p">
        <Button
          fullWidth
          disabled={!file}
          isLoading={loading}
          onClick={sendFile}
          data-testid="submit-file"
        >
          {t('upload.send')}
        </Button>
        <Button fullWidth variant="secondary" onClick={downloadXlsx}>
          {t('labels.downloadXlsx')}
        </Button>
      </div>
    </Container>
  )
}

export default UploadFileSelector
