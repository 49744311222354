// TODO: Use codegen to generate types and use gql files instead ts

// ----------------------------------------------------------------

export interface GetConciliationParams {
  year: string
  month: string
  page: number
  limit: number
  groupName: string
  asc: boolean
  orderBy: string
  status: string
}

export interface GetConciliationsResponse {
  conciliations: ConciliationList
}

export interface ConciliationList {
  data: CompanyConciliation[]
  total: number
  limit: number
  page: number
}

export interface CompanyConciliation {
  id: string
  totalAmount: number
  numberOfInstallments: number
  status: string
  conciliationGroup: ConciliationGroup
}

export interface RemovePayrollDeductions {
  id: string
  reason: string
}

// ----------------------------------------------------------------

export interface GetConciliatorResponse {
  conciliator?: {
    id: string
    email: string
    name: string
    role: string
  }
}

export interface GetConciliatorResponse {
  email: string
}

export interface GetConciliationDetailsResponse {
  conciliation: ConciliationDetails
}

export interface ConciliationGroup {
  id: string
  name: string
  companies: Company[]
  headquartersCompany?: HeadquartersCompany
}

export interface HeadquartersCompany {
  name: string
  document: string
}

export interface Company {
  code: number
  documentNumber: string
  documentType: string
  id: string
  name: string
}
export interface ConciliationDetails {
  id: string
  companyName: string
  companyDocument: string
  conciliationFileUrl?: string
  totalAmount: number
  referenceMonth: number
  referenceYear: number
  payrollDeduction: PayrollDeduction[]
  deductionsContested: PayrollDeduction[]
  deductionsRefused: PayrollDeduction[]
  deductionsNew: PayrollDeduction[]
  conciliationGroup: ConciliationGroup
  status: CONCILIATION_STATUS
}

export enum CONCILIATION_STATUS {
  PENDING = 'PENDING',
  CONTESTED = 'CONTESTED',
  APPROVED = 'APPROVED',
}

export type PayrollDeductionStatus =
  | string
  | 'NEW'
  | 'REFUSED'
  | 'CONTESTED'
  | 'SPLIT'

export type SplitInstallmentType = string | 'EXCEEDED_MARGIN'

export interface SplitPayrollDeduction {
  companyPartialDiscountAmount: number
  reason: string
  status: string
  totalInstallmentAmount: number
  type: SplitInstallmentType
}
export interface PayrollDeduction {
  id: string
  contractType: string
  contractStatus: string
  requestDate: string
  companyName: string
  companyDocument: string
  companyCode: string
  partnerCode: string
  clientName: string
  clientDocument: string
  installmentNumber: number
  numberOfInstallments: number
  dueDate: string
  installmentAmount: number
  contractExternalId: string
  loanAmount: number
  code: string
  situation: string
  reason?: string
  status: PayrollDeductionStatus
  splitPayrollDeduction: SplitPayrollDeduction | null
}

export interface SplitPayrollDeduction {
  companyPartialDiscountAmount: number
  reason: string
  status: string
  totalInstallmentAmount: number
  type: string
}

export interface GetInvoiceDetailResponse {
  invoiceDetails: InvoiceDetail[]
}
export interface InvoiceDetail {
  amount: string
  companiesDocumentNumber: string
  createdAt: string
  id: number
  link: string
  paidAt: string
  payerDocumentNumber: string
  dueDate: string
}
