import { gql, useMutation } from '@creditas/apollo'

export const REMOVE_DEDUCTION = gql`
  mutation RemovePayrollDeductions(
    $removePayrollDeductions: [RemovePayrollDeduction]!
  ) {
    removePayrollDeductions(removePayrollDeductions: $removePayrollDeductions) {
      id
    }
  }
`

const useRemoveDeduction = () => {
  return useMutation(REMOVE_DEDUCTION)
}

export { useRemoveDeduction }
