import { useTranslation } from '@creditas/i18n'
import React from 'react'

import { TextField } from '@creditas/input'

import { FiltersRow } from './FilterListGroups.style'
import { DateSelect } from '../DateSelect'
import { CONCILIATION_STATUS } from '../../services/bff/types'
import { FilterListCompaniesProps } from './'

const FilterListGroups = (props: FilterListCompaniesProps) => {
  const {
    stateReferenceDate: [referenceDate, setReferenceDate],
    stateGroupName: [groupName, setGroupName],
    stateStatus: [status, setStatus],
  } = props
  const { t } = useTranslation()

  function handleReferenceDate(date: Date) {
    setReferenceDate(date)
  }

  function handleFilter(event: React.FormEvent<HTMLInputElement>) {
    setGroupName(event.currentTarget.value || '')
  }

  function handleStatus(event: React.FormEvent<HTMLInputElement>) {
    setStatus(event.currentTarget.value || '')
  }

  return (
    <FiltersRow>
      <TextField
        value={groupName}
        label={t('labels.group.name')}
        onChange={handleFilter}
        InputProps={{ 'data-testid': 'filter-input-company' }}
      />
      <TextField
        select
        value={status}
        label={t('labels.group.status')}
        onChange={handleStatus}
        InputProps={{ 'data-testid': 'filter-input-status' }}
      >
        <option value={''} label={t('conciliationStatus.ALL')} />
        <option
          value={CONCILIATION_STATUS.PENDING}
          label={t('conciliationStatus.PENDING')}
        />
        <option
          value={CONCILIATION_STATUS.APPROVED}
          label={t('conciliationStatus.APPROVED')}
        />
        <option
          value={CONCILIATION_STATUS.CONTESTED}
          label={t('conciliationStatus.CONTESTED')}
        />
      </TextField>
      <DateSelect
        referenceDate={referenceDate}
        onChange={handleReferenceDate}
        yearLabel={t('labels.yearLabel')}
        monthLabel={t('labels.monthLabel')}
      />
    </FiltersRow>
  )
}

export { FilterListGroups }
