import { gql, useMutation } from '@creditas/apollo'

const RESTORE_CONCILIATION_STATUS = gql`
  mutation restoreConciliationStatus($id: ID!) {
    restoreConciliationStatus(id: $id) {
      approvals {
        id
        approvedAt
        conciliator {
          id
          name
          email
          externalId
          role
        }
      }
    }
  }
`

export function useRestoreConciliationStatus() {
  return useMutation<MutationResponse, Variables>(RESTORE_CONCILIATION_STATUS)
}

interface Variables {
  id: string
}

interface MutationResponse {
  restoreConciliationStatus: {
    approvals: Approval[]
  }
}

interface Approval {
  id: string
  approvedAt: string
  conciliator: Conciliator
}

interface Conciliator {
  id: string
  name: string
  email: string
  externalId: number
  role: 'CREDITAS' | 'RH'
}
