import React, { useState, useContext } from 'react'

import { SortByEvent } from '.'
import { useSearchParams } from '../../hooks'

interface SortByContextData {
  sequence: SortByEvent
  setSequence(event: SortByEvent): void
}

interface Props {
  onChangeSort(event: SortByEvent): void
}

export const SortByContext = React.createContext<SortByContextData>(
  {} as SortByContextData,
)

export const useSortBy = () => useContext(SortByContext)

export const SortContext: React.FC<Props> = ({ children, onChangeSort }) => {
  const { getSearchParams } = useSearchParams()
  const { sortBy, asc } = getSearchParams()
  const [sequence, setSequence] = useState<SortByEvent>(() => ({
    sortBy: sortBy ?? '',
    direction: { true: 'asc', false: 'desc' }[asc] ?? '',
  }))
  function emitEvent(event: SortByEvent) {
    setSequence(event)
    onChangeSort(event)
  }
  return (
    <SortByContext.Provider value={{ sequence, setSequence: emitEvent }}>
      {children}
    </SortByContext.Provider>
  )
}
