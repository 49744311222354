import React from 'react'

function IconWarning() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 80 80"
    >
      <circle cx="30" cy="40" r="30" fill="#FFD8BA"></circle>
      <path
        stroke="#1F2D27"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M62.758 24.883L38.516 49.125 26.398 37.004"
      ></path>
      <path
        stroke="#1F2D27"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M67.375 39.5A28.875 28.875 0 1138.5 10.625c4.662 0 9.05 1.133 12.949 3.093"
      ></path>
    </svg>
  )
}

export default IconWarning
