import { Input, Field } from './Style'
import React, { ReactNode } from 'react'

export interface InputFieldProps {
  'data-testid': string
  id?: string
  placeholder: string
  type?: string
  name?: string
  invalid?: boolean
  fullWidth?: boolean
  value?: any
  forwardedRef?: any
  onChange?: (value: any) => void
  onClick?: (value: any) => void
  endIcon?: ReactNode
}

const InputField = ({
  forwardedRef,
  placeholder,
  onChange,
  onClick,
  value,
  'data-testid': testId,
  invalid,
  fullWidth,
  endIcon,
  type = 'text',
  name,
}: InputFieldProps) => {
  return (
    <Field>
      <Input
        className={`${fullWidth ? 'full-width' : ''} ${
          invalid ? 'invalid' : ''
        }`}
        type={type}
        name={name}
        ref={forwardedRef}
        data-testid={testId}
        value={value}
        onClick={onClick}
        onChange={onChange}
        placeholder={placeholder}
      />
      {endIcon}
    </Field>
  )
}

export default InputField
