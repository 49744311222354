import { AppCustomProps } from '../index'
import { pageAction } from './new-relic'
import { EventAnalytics, pushEvent } from './analytics'

const emitEvent = (props: AppCustomProps, value: EventAnalytics) => {
  pushEvent(props, value)
  pageAction(value.action)
}

export { emitEvent }
