import { envVars } from '../envVars'

const pageAction = (action: string) =>
  isReady(() => window.newrelic.addPageAction(`${envVars.appName}:${action}`))

const init = (name: string, release?: string) =>
  isReady(() => window.newrelic.addRelease(name, release))

const noticeError = (error: Error) =>
  isReady(() =>
    window.newrelic.noticeError(error, { appName: envVars.appName }),
  )

const isReady = (callback: Function) =>
  window.newrelic ? callback() : console.warn('Newrelic is not defined')

export { init, pageAction, noticeError }
