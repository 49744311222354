import React from 'react'
import { i18n, initReactI18next } from '@creditas/i18n'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'

import { App } from './App'
import { AppCustomProps } from '.'
import { envVars } from './envVars'
import { init, pageAction } from './helpers'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt'

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  domElementGetter() {
    return document.querySelector(`[data-app="${envVars.appName}"]`)!
  },
})

const bootstrap = [
  reactLifecycles.bootstrap,
  async ({ language }: AppCustomProps) => {
    const conciliation = require(`./translations/${language}.json`)
    const resources = {
      [language]: {
        conciliation,
      },
    }
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources,
        ns: ['conciliation'],
        defaultNS: 'conciliation',
        lng: language,
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      })

    registerLocale('ptBr', ptBR)
    setDefaultLocale(language)
    init(envVars.appName, envVars.release)
    return Promise.resolve()
  },
]

const mount = [
  reactLifecycles.mount,
  _ => {
    pageAction('mount')
    return Promise.resolve()
  },
]

const unmount = [reactLifecycles.unmount]

export { bootstrap, mount, unmount }
