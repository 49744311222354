import React, { useContext, useState } from 'react'
import { useTranslation } from '@creditas/i18n'
import { FeedbackMessage } from '@creditas/feedback-message'
import {
  ActionLink,
  Typography,
  Container,
} from './ConciliationAlertMessage.style'
import { emitEvent } from '../../helpers'
import { AnalyticsActions } from '../../interfaces'
import { CustomPropsContext } from '../../contexts'
import { ConciliationAlertMessageProps } from './index'
import { CONCILIATION_STATUS } from '../../services/bff/types'

export const ConciliationAlertMessage = ({
  status,
}: ConciliationAlertMessageProps) => {
  const { t } = useTranslation()
  const customProps = useContext(CustomPropsContext)
  const [render, setRender] = useState(true)

  const onChange = () => {
    setRender(false)
  }

  function triggerEvent() {
    emitEvent(customProps, {
      value: '',
      action: AnalyticsActions.ClickConciliationInAnaliseMessage,
    })
  }

  function shouldRender() {
    return status === CONCILIATION_STATUS.CONTESTED && render
  }

  return shouldRender() ? (
    <FeedbackMessage variant="info" onClick={triggerEvent}>
      <Container>
        <Typography
          variant="paragraph"
          dangerouslySetInnerHTML={{
            __html: t('labels.conciliationPendingMessage'),
          }}
        />
        <ActionLink data-testid={'closeMessage'} onClick={onChange}>
          {t('labels.close')}
        </ActionLink>
      </Container>
    </FeedbackMessage>
  ) : (
    <span />
  )
}
