import { capitalize } from '../../helpers'

export * from './DateSelect'

export interface DateSelectProps {
  referenceDate: Date
  yearLabel: string
  monthLabel: string
  fromYear: string
  toYear: string
  onChange: (event: Date) => void
}

export interface Option {
  label: string
  value: string
}

export const getMonths = (locale: string) => {
  return Array.from({ length: 12 }, (_, month) => {
    return new Date(
      new Date(0, 0, 15).setMonth(month),
    ).toLocaleDateString(locale, { month: 'long' })
  })
}

export const padStartWithZero = (number: number) => {
  return String(number).padStart(2, '0')
}

export const generateMonthOptions = (locale: string): Option[] => {
  return getMonths(locale).map((label, index) => ({
    label: capitalize(label),
    value: padStartWithZero(index + 1),
  }))
}

/**
 * @returns {string} Year format: YYYY
 */
export const getNextMonthYear = () => {
  const date = new Date(Date.now())
  date.setMonth(date.getMonth() + 1)
  return date.getFullYear().toString()
}

/**
 * @returns {string} Month format: MM
 */
export const getNextMonth = () => {
  const date = new Date(Date.now())
  date.setMonth(date.getMonth() + 1)
  return padStartWithZero(date.getMonth() + 1)
}

export const generateNumbers = (from: number, to: number) => {
  const start = Math.min(from, to)
  const end = Math.max(from, to)
  const length = end - start + 1
  return Array.from({ length }, (_, index) => index + start)
}

export const generateYearOptions = (from: string, to: string): Option[] => {
  return generateNumbers(Number(from), Number(to)).map(year => ({
    label: String(year),
    value: String(year),
  }))
}
