import { gql, useLazyQuery } from '@creditas/apollo'

import { GetInvoiceDetailResponse } from './../types'

export const INVOICES = gql`
  query getInvoiceDetails(
    $month: String!
    $year: String!
    $documentNumbers: [String]!
  ) {
    invoiceDetails(
      month: $month
      year: $year
      documentNumbers: $documentNumbers
    ) {
      id
      link
      paidAt
      amount
      dueDate
      createdAt
      payerDocumentNumber
      companiesDocumentNumber
    }
  }
`

interface Variables {
  month: string
  year: string
  documentNumbers: string[]
}

export const getInvoicesDetails = () => {
  return useLazyQuery<GetInvoiceDetailResponse, Variables>(INVOICES)
}
