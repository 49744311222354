import { gql, useMutation } from '@creditas/apollo'

export const CONTEST_DEDUCTION = gql`
  mutation ContestPayrollDeductions(
    $contestPayrollDeductions: [ChangePayrollDeduction]!
  ) {
    contestPayrollDeductions(
      contestPayrollDeductions: $contestPayrollDeductions
    ) {
      id
    }
  }
`

const useContestDeduction = () => {
  return useMutation(CONTEST_DEDUCTION)
}

export { useContestDeduction }
