import React from 'react'
import { useTranslation } from '@creditas/i18n'

import { Table } from '@creditas/table'
import { Typography } from '@creditas/typography'
import { TableBody, TableRow, TableCell } from '@creditas/table'

import { TableHead } from '..'
import { Card } from '../../components'
import { formatCurrency } from '../../helpers'
import { ConfirmConciliation } from '../ConfirmConciliation'

interface Props {
  amount?: number
  length?: number
  status?: string
  children: React.ReactNode
}

const InstallmentsTotalizer = ({ amount = 0, length = 0, children }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <Card>
        <Table fullWidth>
          <TableHead>
            <TableRow>
              <TableCell textAlign="left">{t('labels.totalValue')}</TableCell>
              <TableCell textAlign="left">
                {t('labels.installmentsNumber')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{formatCurrency(t('locale'), amount)}</TableCell>
              <TableCell>{length}</TableCell>
              <TableCell textAlign="right">{children}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </>
  )
}

export { InstallmentsTotalizer }
