import { Navigation } from '@creditas/icons'
import { styled, css } from '@creditas/stylitas'

import { ContainerProps } from '.'

export const ArrowUp = Navigation.ArrowUp

export const ArrowDown = Navigation.ArrowDown

export const Container = styled.span<ContainerProps>`
  display: flex;
  cursor: pointer;
  align-items: center;

  ${({ align }: ContainerProps) => css`
    justify-content: ${align};
  `}

  > svg {
    fill: currentColor;
  }

  &:hover > svg {
    opacity: 1;
  }
`

export const DisabledArrowDown = styled(Navigation.ArrowDown)`
  opacity: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5 !important;
  }
`
