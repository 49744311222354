import { useTranslation } from '@creditas/i18n'
import React, { useContext, useEffect, useState } from 'react'
import { REASONS, SelectReasonProps } from './index'
import { CustomPropsContext } from '../../contexts'
import { Field, Select } from '../Form'
import { emitEvent } from '../../helpers'
import { useDebounce } from '../../hooks'

const getOptions = (keys, t) => {
  return keys.map(k => ({
    value: k,
    text: t(`reasonsChangeConciliation.${k}`),
  }))
}

const SelectReason = (props: SelectReasonProps) => {
  const {
    disabled,
    onChange,
    options,
    value,
    invalid,
    analytics,
    label,
  } = props
  const customProps = useContext(CustomPropsContext)
  const { t } = useTranslation()
  const [reason, setReason] = useState('')
  const [customReason, setCustomReason] = useState('')
  const debouncedCustomReason = useDebounce(customReason, 500)
  const optionsItems = getOptions(options, t)

  useEffect(() => {
    if (!value) {
      setReason('')
      setCustomReason('')
    }
  }, [value])

  useEffect(() => {
    submit()
    if (!isCustomReason()) {
      setCustomReason('')
    }
  }, [reason, debouncedCustomReason])

  const event = (action: string, reason: string) => {
    if (!reason) return
    emitEvent(customProps, {
      value: reason,
      action,
    })
  }

  function selectReason(e) {
    if (!e.target.value) return
    setReason(e.target.value)
  }

  function changeInput(e) {
    if (!e.target.value) return
    setCustomReason(e.target.value)
  }

  function submit() {
    const value = isCustomReason()
      ? customReason
      : t(`reasonsChangeConciliation.${reason}`)

    if (!reason) return
    onChange(value)
    event(analytics, value)
  }

  function isCustomReason() {
    return reason === REASONS.OTHERS
  }

  return (
    <>
      <Select
        data-testid={'selectReason'}
        label={t(label)}
        onChange={selectReason}
        value={reason}
        variant={invalid ? 'danger' : 'default'}
        disabled={disabled}
        options={optionsItems}
      />
      {isCustomReason() && (
        <Field
          onBlur={submit}
          data-testid={'customReason'}
          label={t('labels.setCustomReason')}
          value={customReason}
          onChange={changeInput}
          disabled={disabled}
        />
      )}
    </>
  )
}

export { SelectReason }
